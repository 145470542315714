export enum IconSize {
  X_SMALL = 'x-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_LARGE = 'x-large',
}

export enum IconColor {
  GREEN = 'green',
  GREY = 'grey',
  ORANGE = 'orange',
  RED = 'red',
  BLUE = 'blue',
  WHITE = 'white',
  SIGMA_COOL_BLUE = 'sigma-cool-blue'
}

export enum IconName {
  ARROW_RIGHT = 'arrow-right',
  ARROW_LEFT = 'arrow-left',
  CALENDAR = 'calendar',
  CHECK = 'check',
  CHEVRON = 'chevron',
  CIRCLE = 'circle',
  CROSS = 'cross',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  IMAGE = 'image',
  IMAGE_SMALL = 'imageSmall',
  PLUS = 'plus',
  REPEAT = 'repeat',
  URL = 'url',
  VIDEO = 'video',
  WARNING = 'warning',
  TWO_FACTOR_AUTHENTICATION = 'twoFactorAuthentication',
  FOLDER = 'folder',
  MORE_OPTIONS = 'more_options',
  LOGOUT = 'logout',
  ACCOUNT = 'account',
  SETTINGS = 'settings',
  UPLOAD = 'upload',
  JACKPOT = 'jackpot',
  DRAG = 'drag',
  RTE = 'rte',
  TIME = 'time',
  TICKERTAPE = 'tickerTape',
  HTML = 'html',
  PRIORITY_LOW = 'priorityLow',
  PRIORITY_MEDIUM = 'priorityMedium',
  PRIORITY_HIGH = 'priorityHigh',
  WEBPACKAGE = 'webpackage',
  TOOL = 'tool',
  USER = 'user',
  LOCK = 'lock',
  ACCESS = 'access',
  DELETE = 'delete',
  INFO = 'info',
  STOP = 'stop',
  STOPWATCH = 'stopWatch',
  TARGET = 'target',
}