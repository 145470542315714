import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';

import { Api } from 'constants/Routes.enum';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const TriggerDropdown = ({ poolId, ...props }) => {
  const dispatch = useDispatch();

  const [triggers, setTriggers] = useState([]);
  const [loadingTriggers, setLoadingTriggers] = useState(true);

  useEffect(() => {
    setLoadingTriggers(true);

    const promise = dispatch(
      fetchData(reverse(Api.TOPICS, { pool_id: poolId })),
    );
    promise
      .then(json => json.json())
      .then(({ topics }) => {
        setTriggers(
          topics.map(topic => {
            return {
              label: topic.public_name,
              value: topic.id,
            };
          }),
        );
      })
      .finally(() => {
        setLoadingTriggers(false);
      });
  }, [dispatch, poolId]);

  return (
    <Dropdown
      {...props}
      values={triggers}
      hasEmptyOption={false}
      placeholder="Select input signal"
      isLoading={loadingTriggers}
    />
  );
};

export default TriggerDropdown;
