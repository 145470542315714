import classNames from 'classnames';
import { FormikValues, useFormik } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';
import Heading from 'components/typography/heading/Heading';

import { fetchInputDevicesAction } from 'store/inputSignals/inputDevices/inputDevicesActions';
import { RootState } from 'store/rootState';

import {
  InputDevicesFilterProps,
  InputDevicesFilterStorage,
} from './InputDevicesFilter.types';

import './InputDevicesFilter.scss';

const InputDevicesFilter: FC<InputDevicesFilterProps> = ({
  localStorageItem,
}) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const filterStorage: InputDevicesFilterStorage = JSON.parse(
    localStorage.getItem(localStorageItem) || '{}',
  );

  const [openFilters, setOpenFilters] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const initialValues = {
    keyword: '',
    location_id: '',
  };
  const filterInputDevices = useCallback(
    (values: FormikValues) => {
      if (isInitialized) {
        const OnlyEnteredValues = Object.fromEntries(
          Object.entries(values).filter(([, v]) => v !== ''),
        );

        localStorage.setItem(
          localStorageItem,
          JSON.stringify(OnlyEnteredValues),
        );

        dispatch(
          fetchInputDevicesAction({
            keyword: values.keyword,
            location_id: values.location_id
          }),
        );
      }
    },
    [dispatch, isInitialized, localStorageItem],
  );

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({ initialValues, onSubmit: filterInputDevices });

  const resetFilter = () => {
    resetForm();
    localStorage.removeItem(localStorageItem);
    setFormKey(formKey + 1);
    filterInputDevices(initialValues);
  };

  useEffect(() => {
    if (filterStorage && !isInitialized) {
      if (filterStorage.keyword) {
        setFieldValue('keyword', filterStorage.keyword);
      }
      if (filterStorage.location_id) {
        setFieldValue('location_id', filterStorage.location_id);
      }

      setOpenFilters(
        filterStorage.location_id !== undefined &&
          filterStorage.location_id !== 0,
      );
    }
    setInitialized(true);
  }, [dispatch, setFieldValue, isInitialized, filterStorage]);

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames('input-devices-filter')}
    >
      <div className="input-devices-filter__bar">
        <div className="input-devices-filter__input">
          <Input
            type="text"
            label={
              <Heading level={3} noMargin>
                Search
              </Heading>
            }
            name="keyword"
            id="search_keyword"
            value={values.keyword}
            scheme="white"
            onChange={handleChange}
            placeholder="What are you looking for?"
          />
        </div>

        <div className="input-devices-filter__options">
          <Button
            text={openFilters ? 'Less filters' : 'More filters'}
            size="medium"
            scheme="link"
            className="pool-filter__option"
            hasShadow={false}
            onClick={() => setOpenFilters(!openFilters)}
          />
          <Button
            tag="button"
            text="Reset"
            size="medium"
            scheme="link"
            className="input-devices-filter__option"
            hasShadow={false}
            onClick={resetFilter}
            disabled={values.keyword === '' && values.location_id === ''}
          />
          <Button tag="button" type="submit" text="Search" size="medium" />
        </div>
      </div>

      <AnimateHeight duration={300} height={openFilters ? 'auto' : 0}>
        <div className="input-devices-filter__advanced">
          <Heading level={3}>Filter options</Heading>
          <LocationDropdown
            id="Location_id"
            name="location_id"
            label="Location"
            width={220}
            value={values.location_id}
            onChange={(name: string, value: string) => {
              setFieldValue(name, value);
              handleSubmit();
            }}
            multiple={false}
            showAllLocations
          />
        </div>
      </AnimateHeight>
    </form>
  );
};

export default InputDevicesFilter;
