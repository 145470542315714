import React from 'react';
import { useDispatch } from 'react-redux';

import IconButton from 'components/button/IconButton';
import Heading from 'components/typography/heading/Heading';
import Divider from 'components/popup-context/divider/Divider';
import { List, ListItem } from 'components/popup-context/list/List';
import AddButtonWithContextMenu from 'components/button/AddButtonWithContextMenu';
import { popupActionSet } from 'store/popup/popupActions';
import PopupTypes from 'constants/PopupTypes.enum';

const MediaButton = () => {
  const dispatch = useDispatch();

  const config = {
    enableWebpackage: false, // functionality is turned of for now, because client only want's to be able to do this themselves. Feature needs to be adjusted later to make this possible.
  };

  return (
    <AddButtonWithContextMenu buttonHoverText="Add media">
      <Heading level={3}>Upload</Heading>
      <List flatten>
        <ListItem>
          <IconButton
            tag="a"
            iconName="upload"
            iconSize="small"
            text="Upload files from your device"
            size="extra-small"
            handler={() => dispatch(popupActionSet(PopupTypes.MEDIA_UPLOAD))}
          />
        </ListItem>
      </List>

      <Divider />

      <Heading level={3}>Create</Heading>
      <List flatten>
        <ListItem>
          <IconButton
            tag="a"
            iconName="url"
            iconSize="small"
            text="URL, RSS or other link type"
            size="extra-small"
            handler={() => dispatch(popupActionSet(PopupTypes.MEDIA_URL))}
          />
        </ListItem>
        <ListItem>
          <IconButton
            tag="a"
            iconName="jackpot"
            iconSize="small"
            text="Jackpot value"
            size="extra-small"
            handler={() =>
              dispatch(popupActionSet(PopupTypes.MEDIA_JACKPOT_VALUE))
            }
          />
        </ListItem>
        <ListItem>
          <IconButton
            tag="a"
            iconName="tickerTape"
            iconSize="small"
            text="Ticker tape"
            size="extra-small"
            handler={() =>
              dispatch(popupActionSet(PopupTypes.MEDIA_TICKER_TAPE))
            }
          />
        </ListItem>
        <ListItem>
          <IconButton
            tag="a"
            iconName="rte"
            iconSize="small"
            text="Rich text"
            size="extra-small"
            handler={() => dispatch(popupActionSet(PopupTypes.MEDIA_RICH_TEXT))}
          />
        </ListItem>
        {config.enableWebpackage && (
          <ListItem>
            <IconButton
              tag="a"
              iconName="webpackage"
              iconSize="small"
              text="Webpackage"
              size="extra-small"
              handler={() =>
                dispatch(popupActionSet(PopupTypes.MEDIA_WEBPACKAGE))
              }
            />
          </ListItem>
        )}
      </List>
    </AddButtonWithContextMenu>
  );
};

export default MediaButton;
