
import { untilTypeName } from 'components/popup/trigger/Trigger.enum';
import { DeviceResolution, DeviceResolutionId, DeviceRotation } from 'components/popup/device/DeviceEdit.types';
import { PendingChanges } from 'components/popup/trigger/Trigger.types';
import { Trigger } from 'components/popup/inputs/inputSignals/InputSignalEdit.types';
import { LogDetailsItemType } from 'components/popup/log-details/LogDetails.enum';
import PriorityOptions from 'components/form/priority/Priority.enum';

import ApprovalStates from "constants/ApprovalStates";
import PopupTypes from 'constants/PopupTypes.enum';
import UserRight from 'constants/UserRight.enum';

import { SetInfoBar } from 'store/info/infoActions.types';
import { Media, MediaItemType } from 'store/media/Media.types';
import { Events } from "./eventState";
import { Customer } from './customers/customer.types';

export interface ArrangementMedia {
  [key: string]: {
    active: boolean;
    id: number;
    name: string;
    deleted_at?: Date;
    created_at: Date;
    updated_at: Date;
    filename: string;
    filetype: MediaItemType;
    used_count: number;
    media_file_file_name: string;
    media_file_content_type?: any;
    media_file_file_size: number;
    media_file_updated_at?: Date;
    customer_id: number;
    media_folder_id?: number;
    media_source: string;
  }
}

export enum ArrangementTemplateOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait'
}

export interface ArrangementTemplate {
  id?: number;
  name: string;
  archived: boolean;
  created_at: Date;
  updated_at: Date;
  height: number;
  width: number;
  orientation: ArrangementTemplateOrientation;
  template_areas: ArrangementTemplateAreas[];
}

export interface ArrangementTemplateAreas {
  name: string;
  number: number;
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface Arrangement {
  media: ArrangementMedia[];
  active: boolean;
  approval_state: ApprovalStates;
  arrangement_media_items_change?: Date;
  created_at: Date;
  customer_id: number;
  deleted_at?: Date;
  id?: number;
  name: string;
  pending_approval: boolean;
  pool_id: number;
  template?: ArrangementTemplate;
  template_id?: number;
  type: string;
  updated_at?: Date;
  hidden?: boolean;
}

export interface Arrangements {
  arrangements: Arrangement[],
  request: boolean;
  selectable: boolean;
}

export interface ArrangementTemplate {
  id?: number;
  name: string;
  archived: boolean;
  created_at: Date;
  updated_at: Date;
  height: number;
  width: number;
  orientation: ArrangementTemplateOrientation;
  template_areas: ArrangementTemplateAreas[];
}

export interface ArrangementTemplateAreas {
  name: string;
  number: number;
  width: number;
  height: number;
  x: number;
  y: number;
}


export interface DevicesFilterOptionsLocation {
  id: number;
  name: string;
}

export interface DevicesFilterOptionsGroup {
  id: number;
  name: string;
  location: number;
}

export interface DevicesFilterOptions {
  locations?: DevicesFilterOptionsLocation[];
  groups?: DevicesFilterOptionsGroup[];
}
export interface Locations {
  request: boolean;
  locations: LocationsFilter[];
}

export interface LocationsFilter {
  id: string;
  name: string;
  groups: number;
  users: number;
  customer_id: number;
  customer_name: string;
}
export interface UserRole {
  id: number;
  name: string;
  isAdmin: boolean
  users: string[];
  customer_id: number;
  customer_name: string;
}

export interface UserRoles {
  userRoles: UserRole[];
  request: boolean;
}

export interface User {
  id: number;
  status: string;
  firstname: string;
  lastname: string;
  email: string;
  roles: UserRole[];
  locations: string[];
  customer_id: number;
  customer_name: string;
}

export interface Users {
  request: boolean;
  users: User[];
}

interface RootUser extends User {
  UserId: string;
  request: boolean;
  created_at: string;
}

export enum ComponentStatus {
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
}


export enum InputDeviceConnection {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum resendEmailValues {
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed',
}
export interface Location {
  name: string;
  id: number;
}

export interface InputDeviceInputSignal {
  active: boolean;
  id: number;
  name: string;
}

export interface InputDevice {
  id: number;
  in_use: boolean;
  status?: ComponentStatus;
  name: string;
  serial_number: string;
  version: string;
  locations: Location[];
  connection: InputDeviceConnection;
  input_signals: InputDeviceInputSignal[];
  customer?: string;
  customer_id?: number;
}

export interface InputDevices {
  request: boolean;
  inputDevices: InputDevice[];
  selectedInputDevice: {
    request: boolean,
    inputDevice?: InputDevice,
  }
}

export enum InputSignalStatus {
  ACTIVE = 'active',
  DEACTIVE = 'deactive',
}

export interface InputSignalLatestDataData {
  [key: string]: any;
}

export interface InputSignalLatestData {
  data?: InputSignalLatestDataData;
  timestamp?: string;
  type?: string;
}

export interface InputSignal {
  id: number;
  status: InputSignalStatus;
  name: string;
  input_device?: { id: string, name: string };
  locations: Location[];
  type: string;
  times_used: number;
  latest_data: InputSignalLatestData;
  customer?: string;
  customer_id?: number;
}

export interface InputSignals {
  request: boolean;
  inputSignals: InputSignal[];
}

export enum ArrangementType {
  REGULAR = 'RegularArrangement',
  TRIGGER = 'TriggerArrangement'
}

export interface Pool {
  id: string;
  name: string;
  location: string;
  customer: string;
  approval_requested: boolean;
  sign_information: {
    count: string
  };
}

export interface Pools {
  request: boolean;
  pools: Pool[];
}

interface Popup {
  type: PopupTypes;
  data: {
    id: number;
    name: string,
    active: boolean;
    title: string;
    children?: React.ReactNode
    isDefault: boolean;
    pending_changes: PendingChanges;
    pending_approval: boolean;
    startDate: string;
    endDate: string;
    roundTime: boolean;
    rrule: string;
    arrangement: Arrangement;
    priority: PriorityOptions;

    //Arrangement
    media?: any;
    // formValues: FormValues;

    type: ArrangementType;

    //TriggerPopPup
    trigger_id: string;
    until_type: untilTypeName;
    until_value: string;

    //MediaJackpotValue
    jackpot: any; //function shape
    folder_id: number;
    shows: any; //array of function shape met props
    created_at: string;
    file_size: string;
    filename: string;

    //MediaTickerTape
    ticker_tape: any; //TODO Add enums

    //DeviceEdit
    mute: boolean;
    resolution_id: DeviceResolutionId;
    rotation: DeviceRotation;

    //DeviceEdit Detail
    isInPool: any;
    deviceValues: Array<{
      value: string | number;
      label: string
    }>

    //MediaFolder
    parent_id: any;
    media_folder_id: any;

    //LogDetails
    item_id: number;
    item_name: string;
    item_type: LogDetailsItemType;
    changes: string[];
    deleted?: string;
    deleted_log?: string;
    description: string;

    //AccountSortingTwoFactor
    text: any;

    //AccountSettingsVerifyEmail
    emailData: string;

    //User
    firstName: string;
    lastName: string;
    email: string;
    locations: Location[];
    userRoles: UserRole[];

    //UserRole
    rights: UserRight[];
    isAdmin: boolean;

    //Location
    timezone: string;
    users?: User[];

    //InputDevice
    in_use: boolean;
    serial_number: string;
    version: string;
    connection: InputDeviceConnection;
    input_signals: InputDeviceInputSignal[];
    latest_data: InputSignalLatestData;

    //InputSignalEdit
    triggers: Trigger[];
    input_device?: { id: string, name: string };

    status: ComponentStatus;

    IntrinsicAttributes: any;
  }
}

export interface DevicesSigns {
  id: number;
  name: string;
  serial_number: string;
  online_status: DevicesSignStatus;
  height?: any;
  width?: any;
  total_storage?: any;
  available_storage?: any;
  version_number?: number;
  orientation?:null
  location_name: string;
  updated_at: Date;
  pool_name: string;
  pool_id: number;
  customer: string;
  customer_id: number;
}

export enum DevicesSignStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export interface AccountSettings {
  two_factor_is_set?: boolean;
  two_factor_resend_email: 'unsent' | 'processing' | 'success' | 'failed';
  verify_email_email?: string;
  verify_email_is_set?: boolean;
}

export interface RootState {
  auth: {
    customer: string;
    error: string;
    two_factor_resend_email: resendEmailValues;
    auth_token: string;
    two_factor_required: boolean;
    two_factor_is_set: boolean;

    user: {
      name: string;
      username: string;
      id: number;
      rights?: UserRight[];
      email?: string;
      roles: string;
    };
  };
  superAdmin: {
    customerOverride: string;
  };
  customers: {
    customers: Customer[]
  }
  info: {
    bar: SetInfoBar;
    error?: string;
  };
  pools: Pools;
  pool: {
    request: boolean;
    default_arrangement: {
      id: number;
      name: string;
      type: string;
      template_id: number;
      template: string;
      active: boolean;
    };
    default_arrangement_approval_state: ApprovalStates;
    default_arrangement_pending_approval: boolean;
    id: number;
    name: string;
    created_at: string;
    location: {
      name: string;
      timezone_info: string;
    };
  };
  events: Events;
  popup: Popup;
  media: Media;
  arrangements: Arrangements;
  devices: {
    resolutions: DeviceResolution[];
    filterOptions: DevicesFilterOptions;
    request: any;
    signs: DevicesSigns[];
  };
  triggers: {
    trigger_public_name?: string;
    triggers: Trigger[];
    request: any;
  };
  accountSettings: AccountSettings;
  locations: Locations;
  users: Users;
  user: RootUser;
  userRoles: UserRoles;
  inputs: {
    inputDevices: InputDevices;
    inputSignals: InputSignals;
  };
}
