import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Dropdown from 'components/form/dropdown/Dropdown';
import DropdownMultiple from 'components/form/dropdown-multiple/DropdownMultiple';
import { fetchLocationsAction } from 'store/locations/locationsActions';

const useLocationDropdown = (showAllLocations, disabled, value) => {
  const dispatch = useDispatch();
  const { request, locations } = useSelector(state => state.locations);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    if (disabled) {
      return () => {
        setLocationList(value);
      };
    }
    dispatch(fetchLocationsAction(showAllLocations));

    return () => {
      setLocationList([]);
    };
  }, [dispatch, showAllLocations, value, disabled]);

  useEffect(() => {
    const listedLocations = disabled ? value : locations;

    setLocationList(
      listedLocations
        .map(location => {
          return {
            label: location.name,
            value: location.id,
          };
        })
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }),
    );
  }, [locations, value, disabled]);

  return useMemo(() => ({ request, locationList }), [request, locationList]);
};

const LocationDropdown = ({ multiple, showAllLocations, ...props }) => {
  const { value, disabled } = props;
  const { locationList, loadingLocations } = useLocationDropdown(
    showAllLocations,
    disabled,
    value,
  );
  let selectedValuesAsValues = [];

  if (disabled) {
    selectedValuesAsValues = value.map(location => {
      return {
        label: location.name,
        value: location.id,
      };
    });
  }

  const selectedValues = () => {
    if (disabled) {
      return selectedValuesAsValues;
    }
    return locationList;
  };

  return (
    <>
      {multiple ? (
        <DropdownMultiple
          {...props}
          values={selectedValues()}
          isLoading={loadingLocations}
          sortOptions
          selectedItemsLabel={
            selectedValues().length > 1 ? 'locations' : 'location'
          }
        />
      ) : (
        <Dropdown
          {...props}
          values={selectedValues()}
          hasEmptyOption={false}
          placeholder="Select location"
          isLoading={loadingLocations}
        />
      )}
    </>
  );
};

LocationDropdown.propTypes = {
  multiple: PropTypes.bool,
  showAllLocations: PropTypes.bool,
  onChange: PropTypes.func,
};

LocationDropdown.defaultProps = {
  multiple: false,
  showAllLocations: false,
  onChange: () => {},
};

export default LocationDropdown;
