import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';

import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';

import './Dropdown.scss';

const Dropdown = ({
  label,
  name,
  values,
  value,
  onChange,
  id,
  width,
  disabled,
  isLoading,
  error,
  isChanged,
  placeholder,
  hasEmptyOption,
  purpose,
  sortOptions,
  dataCy,
  className,
  containerClassName,
}) => {
  const dropdownStyling = {
    container: provided => ({
      ...provided,
      width,
    }),
    indicatorSeparator: () => ({
      border: 0,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: 'none',
    }),
    singleValue: provided => ({
      ...provided,
      color: 'inherit',
    }),
  };

  const emptyOption = {
    label: placeholder,
    value: '',
  };

  const [selectedValue, setSelectedValue] = useState(emptyOption);

  useEffect(() => {
    const matchSelection =
      values &&
      values.find(
        option => option.value === value || equal(option.value, value),
      );

    setSelectedValue(
      matchSelection !== undefined
        ? matchSelection
        : {
            label: placeholder,
            value: '',
          },
    );
  }, [placeholder, values, value, setSelectedValue]);

  const options = useMemo(() => {
    let optionsList = values;
    const sortItems = v => {
      return v.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
    };

    if (sortOptions && values.length > 0) {
      optionsList = sortItems(values);
    }

    if (hasEmptyOption) {
      optionsList = [emptyOption, ...optionsList];
    }

    return optionsList;
  }, [values, hasEmptyOption, emptyOption, sortOptions]);

  return (
    <Label
      label={label}
      idFor={id}
      className={classNames('form__dropdown__label', className)}
    >
      <div
        className={classNames('form__dropdown__container', containerClassName, {
          'form__dropdown__container--disabled': disabled,
        })}
        data-cy={dataCy}
      >
        <Select
          placeholder={isLoading ? 'loading...' : placeholder}
          styles={dropdownStyling}
          name={name}
          id={id}
          className={classNames('form__dropdown', {
            'form__dropdown--changed': isChanged,
            [`form__dropdown--${purpose}`]: purpose,
          })}
          classNamePrefix="form__dropdown"
          options={options}
          value={isLoading ? 'loading...' : selectedValue}
          isDisabled={disabled}
          isLoading={isLoading}
          onChange={e => onChange(name, e && e.value)}
          menuPosition="fixed"
        />

        {!isLoading && (
          <Icon
            name="chevron"
            className={classNames('', {
              'form__dropdown__icon--disabled': disabled,
              [`form__dropdown__icon--${purpose}`]: purpose,
            })}
          />
        )}
        {error && <span className="form__container-error" />}
      </div>

      {error && typeof error === 'string' && (
        <p className="form__error" style={{ width }}>
          {error}
        </p>
      )}
    </Label>
  );
};

Dropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onChange: PropTypes.func,
  id: PropTypes.string,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isChanged: PropTypes.bool,
  placeholder: PropTypes.string,
  hasEmptyOption: PropTypes.bool,
  purpose: PropTypes.oneOf(['add', 'navigate']),
  sortOptions: PropTypes.bool,
  dataCy: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

Dropdown.defaultProps = {
  label: '',
  name: '',
  values: [],
  value: '',
  onChange: () => {},
  id: null,
  width: null,
  disabled: false,
  isLoading: false,
  error: false,
  isChanged: false,
  placeholder: 'Select...',
  hasEmptyOption: true,
  purpose: 'add',
  dataCy: null,
  className: null,
  sortOptions: false,
  containerClassName: null,
};

export default Dropdown;
