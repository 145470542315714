import React, { FC, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';
import { useHistory, useLocation } from 'react-router-dom';
import { reverse } from "named-urls";
import { History } from 'history';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Label from 'components/form/label/Label';
import Button from 'components/button/Button';
import ButtonSelectShow from 'components/button/buttonSelectShow/ButtonSelectShow';
import { popupActionClear } from 'store/popup/popupActions';
import Icon from 'components/icon/Icon';
import TriggerDropdown from 'components/trigger/dropdown/TriggerDropdown';
import SubmitButton from 'components/form/submit-button/SubmitButton';
import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';
import DragSwitch from 'components/form/dragSwitch/DragSwitch';
import PopupLabels from 'components/popup-labels/PopupLabels';

import HistoryLocationState from 'constants/HistoryState';
import { Paths } from 'constants/Routes.enum';
import UserRight from 'constants/UserRight.enum';

import {
  addTriggerAction,
  deleteTriggerAction,
  toggleActiveTriggerAction,
} from 'store/triggers/triggersActions';
import { infoBarHide, setInfoBar, setInfoBarShow } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { hasUserRights } from 'store/auth/hasUserRights';

import { RootState } from 'store/rootState';

import { TriggerFormInitialValues, TriggerPopupProps } from './Trigger.types';

import './Trigger.scss';
import { untilTypeName } from './Trigger.enum';

export const clearHistoryFromTriggerState = (history: History, locationState: HistoryLocationState) => {
  if (locationState) {
    const clearHistoryFromTriggerState = {
      state: {
        fromTrigger: undefined,
        fromTriggerFormFormValues: undefined,
        newTrigger: undefined,
      },
    };
    history.replace(clearHistoryFromTriggerState);
  }
};

const Trigger: FC<TriggerPopupProps> = ({
  id,
  name,
  trigger_id,
  arrangement,
  until_type,
  until_value,
  active,
  pending_approval,
  pending_changes,
}) => {
  const { state } = useLocation<HistoryLocationState>();
  const history = useHistory();
  const dispatch = useDispatch();
  const pool = useSelector((state: RootState) => state.pool);
  const infoBar = useSelector((state: RootState) => state.info.bar);
  const hasTriggerEditRights = dispatch(hasUserRights(UserRight.TRIGGERS_EDIT));

  const [isActive, setActive] = useState(active);
  const [isSubmitting, setSubmitting] = useState(false);

  // Initial values coming from component props
  // Values are updated if props are changed (like from approval flow)
  const initialValues = useMemo(() => {
    return {
      name: name || '',
      triggerId: trigger_id,
      arrangementId: (arrangement && arrangement.id) ? arrangement.id.toString() : '',
      untilType: until_type || 'timer',
      untilValue: until_value,
      pending_approval,
      pending_changes,
    }
  }, [name,
    trigger_id,
    arrangement,
    until_type,
    until_value,
    pending_approval,
    pending_changes
  ]);

  // Initial values as state to update form
  const [initialForm, setInitialForm] = useState<TriggerFormInitialValues>(() => {
    const stateValues = state?.fromTriggerFormFormValues;
    // Fill with values from router state if available
    if (stateValues) {
      return stateValues;
    }

    return initialValues;
  });



  // Update state with new values from props
  useEffect(() => {
    // Only when router state is not available
    if (!state?.fromTriggerFormFormValues) {
      setInitialForm(initialValues);
    }
  }, [initialValues, state]);

  // Validation schema used for form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
    triggerId: Yup.number()
      .min(1, 'Trigger is a required field')
      .required('Trigger is a required field'),
    arrangementId: Yup.number()
      .min(1, 'Show is a required field')
      .required('Show is a required field'),
    untilType: Yup.string().required('Until is a required field'),
    untilValue: Yup.number()
      .when('untilType', {
        is: untilType => untilType === 'timer',
        then: Yup.number()
          .min(1, 'Timer is a required field')
          .required('Timer is a required field'),
      })
      .when('untilType', {
        is: untilType => untilType === 'trigger',
        then: Yup.number()
          .min(1, 'Trigger is a required field')
          .required('Trigger is a required field'),
      }),
  });

  const onCancel = () => {
    clearHistoryFromTriggerState(history, state);
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(setInfoBarShow({ show: false }));
    }
  };

  const onSubmit = (values: TriggerFormInitialValues) => {
    if (equal(values, initialValues)) {
      clearHistoryFromTriggerState(history, state);
      dispatch(popupActionClear());
      dispatch(setInfoBarShow({ show: false }));
      return;
    }

    setSubmitting(true);

    const triggerData = new FormData();
    triggerData.append('name', values.name);
    triggerData.append('pool_id', pool.id.toString());
    triggerData.append('topic_id', values.triggerId);
    triggerData.append('arrangement_id', values.arrangementId);
    triggerData.append('until_type', values.untilType);
    triggerData.append('until_value', values.untilValue);

    dispatch(addTriggerAction(triggerData, id, setSubmitting, state, history));
  };

  const selectShowClick = () => {
    const location = (id: number, newTrigger: boolean) => {
      return {
        pathname: reverse(Paths.POOLS_ARRANGEMENTS, { id: pool.id }),
        state: {
          // @ts-ignore
          ...history?.location?.state,
          fromTrigger: id,
          fromTriggerFormFormValues: values,
          newTrigger:
            // @ts-ignore
            newTrigger || history?.location?.state?.newTrigger,
        },
      };
    };

    if (id === undefined || id === null) {
      history.push(location(id, true));
    } else {
      history.push(location(id, false));
    }

    dispatch(popupActionClear());
  };

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const clearSelectedShow = () => {
    setFieldValue('arrangementId', '');
  };

  const toggleUntil = (checked: boolean) => {
    setFieldValue('untilType', checked ? untilTypeName.TIMER : untilTypeName.TRIGGER);
    setFieldValue('untilValue', '');
  };

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset className="fieldset--flatten">
          <PopupLabels labels={[pool.name, pool.location.name]} />
          <div className="trigger__header-layout">
            <FormBlock flatten>
              <Input
                containerClassName="trigger__header-layout__input"
                type="text"
                name="name"
                id="name"
                placeholder="New trigger"
                value={
                  (pending_approval && pending_changes?.title) ||
                  values.name
                }
                onChange={handleChange}
                fontSize="large"
                suffix={<Icon name="edit" color="grey" />}
                error={touched.name && errors.name}
                disabled={pending_approval || !hasTriggerEditRights}
                isChanged={
                  pending_approval &&
                  (!!pending_changes?.title ||
                    pending_changes === undefined)
                }
              />
            </FormBlock>
            {id && (
              <DragSwitch
                className="trigger__header-layout__drag-switch"
                id="active"
                name="active"
                labelOn="Active"
                labelOff="Disabled"
                isDisabled={
                  pending_approval || !hasTriggerEditRights || isSubmitting
                }
                isChecked={isActive}
                onChange={() => {
                  dispatch(
                    toggleActiveTriggerAction(
                      id,
                      isActive,
                      setActive,
                      setSubmitting,
                    ),
                  );
                }}
              />
            )}
          </div>
        </fieldset>
      </PopupStickyHeader>
      <PopupStickyMain>
        <fieldset>
          <legend>Trigger rules</legend>
          <FormBlock className="trigger__form-block trigger__form-block--dotted-line">
            <TriggerDropdown
              label={
                <div className="trigger__label">
                  <Icon
                    size="small"
                    name="target"
                    className="trigger__label-icon"
                  />
                  When this is activated
                </div>
              }
              poolId={pool.id}
              name="triggerId"
              id="triggerId"
              onChange={setFieldValue}
              value={
                (pending_approval && pending_changes?.trigger_id) ||
                values.triggerId
              }
              error={touched.triggerId && errors.triggerId}
              disabled={pending_approval || !hasTriggerEditRights}
              isChanged={
                pending_approval &&
                (!!pending_changes?.trigger_id ||
                  pending_changes === undefined)
              }
            />
          </FormBlock>

          <FormBlock className="trigger__form-block trigger__form-block--dotted-line">
            <div>
              <Label
                className="trigger__label"
                label={
                  <>
                    <Icon
                      size="small"
                      name="imageSmall"
                      className="trigger__label-icon"
                    />
                    Show this
                  </>
                }
              />
              <ButtonSelectShow
                onClick={selectShowClick}
                selected={values.arrangementId !== ''}
                error={touched.arrangementId && errors.arrangementId}
                arrangement={(pending_approval && pending_changes?.arrangement) || state?.arrangement || arrangement}
                pendingApproval={pending_approval && (pending_changes?.arrangement || !pending_changes)}
                disabled={pending_approval || !hasTriggerEditRights}
                onClearSelection={clearSelectedShow}
              />
            </div>
          </FormBlock>

          <FormBlock className="trigger__form-block">
            <div>
              <Label
                className="trigger__label"
                label={
                  <>
                    <Icon
                      size="small"
                      name="stop"
                      className="trigger__label-icon"
                    />
                    Until
                  </>
                }
              />
              <DragSwitch
                id="UntilSwitch"
                name="UntilSwitch"
                labelOn="A signal is activated"
                labelOff="A timer expires"
                type="alternate-toggle"
                isChecked={
                  (!pending_changes?.until_type && values.untilType === 'trigger') ||
                  pending_changes?.until_type === 'trigger'
                }
                onChange={() =>
                  toggleUntil(
                    (!pending_changes?.until_type && values.untilType === 'trigger') ||
                    pending_changes?.until_type === 'trigger',
                  )
                }
                isDisabled={pending_approval || !hasTriggerEditRights}
                isChanged={
                  pending_approval &&
                  (!!pending_changes?.until_type ||
                    pending_changes === undefined)
                }
              />
            </div>
          </FormBlock>
          <FormBlock className="trigger__form-block">
            {(pending_approval &&
              pending_changes?.until_type === 'timer') ||
              (pending_changes?.until_topic === undefined && values.untilType === 'timer') ? (
              <Input
                type="number"
                name="untilValue"
                id="untilValue"
                scheme="white"
                value={
                  (pending_approval && pending_changes?.until_value) ||
                  values.untilValue
                }
                placeholder="15"
                onChange={handleChange}
                error={
                  touched.untilValue && errors.untilValue ? true : null
                }
                prefix={
                  <Icon
                    color="sigma-cool-blue"
                    size="small"
                    name="stopWatch"
                  />
                }
                suffix="minutes"
                width={150}
                disabled={pending_approval || !hasTriggerEditRights}
                isChanged={
                  pending_approval &&
                  (!!pending_changes?.until_value ||
                    pending_changes === undefined)
                }
              />
            ) : (
              <TriggerDropdown
                poolId={pool.id}
                name="untilValue"
                id="untilValue"
                value={
                  (pending_approval && pending_changes?.until_topic?.id) ||
                  Number(values.untilValue)
                }
                error={
                  touched.untilValue && errors.untilValue ? true : null
                }
                onChange={setFieldValue}
                disabled={pending_approval || !hasTriggerEditRights}
                isChanged={
                  pending_approval &&
                  (!!pending_changes?.until_value ||
                    pending_changes === undefined)
                }
              />
            )}
          </FormBlock>

          {touched.untilValue && errors.untilValue && (
            <FormBlock
              className="trigger__form-block"
              flatten
              error={touched.untilValue && errors.untilValue}
            />
          )}
        </fieldset>
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(id && hasTriggerEditRights && (
            <Button
              tag="a"
              size="medium"
              text="Delete"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(
                  setInfoBar({
                    message:
                      'Are you sure you want to delete this trigger?',
                    action: [
                      {
                        text: 'Yes, Delete',
                        type: 'button',
                        color: 'blue',
                        handle: () => dispatch(deleteTriggerAction(id)),
                      },
                      {
                        text: 'Cancel',
                        type: 'link',
                        handle: () => dispatch(infoBarHide()),
                      },
                    ],
                    state: InfoBarState.ERROR,
                  }),
                );
              }}
              disabled={!hasTriggerEditRights || isSubmitting}
            />
          )) || (
              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  onCancel();
                }}
                disabled={isSubmitting}
              />
            )}
          <SubmitButton
            canSave={!pending_approval}
            disabled={isSubmitting}
          />
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  )
};

export default Trigger;
