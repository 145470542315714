import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';
import { reverse } from 'named-urls';
import PropTypes from 'prop-types';

import UserRight from 'constants/UserRight.enum';
import RegexOptions from 'constants/RegexOptions';
import { Paths, Api } from 'constants/Routes.enum';
import MediaItemTypes from 'constants/MediaItemTypes.enum';

import {
  infoBarHide,
  setInfoBar,
  setInfoBarShow,
} from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear } from 'store/popup/popupActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import {
  addMediaAction,
  deleteMediaAction,
  fetchMediaValueTypesAction,
  mediaDeleted,
} from 'store/media/mediaActions';
import fetchData from 'store/fetchData';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import Dropdown from 'components/form/dropdown/Dropdown';
import ColorPickerInput from 'components/form/colorPicker/ColorPickerInput';
import Icon from 'components/icon/Icon';
import MediaPlacementFieldset from 'components/popup/media-preview/MediaPlacementFieldset';
import MediaShowsOverviewFieldset from 'components/popup/media-preview/MediaShowsOverviewFieldset';
import MediaPreviewDetails from 'components/popup/media-preview/MediaPreviewDetails';
import FormMessage from 'components/form/formMessage/FormMessage';
import {
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
} from 'components/popup/Popup';

const MediaTickerTape = ({
  id,
  name,
  ticker_tape,
  folder_id,
  shows,
  created_at,
}) => {
  const dispatch = useDispatch();
  const infoBar = useSelector(state => state.info.bar);
  const { folder_info } = useSelector(state => state.media);

  const tickerTapeTypes = useSelector(
    state => state.media?.value_types?.tickerTape,
  );

  const authToken = useSelector(state => state.auth.auth_token);
  const hasMediaEditRights = dispatch(hasUserRights(UserRight.MEDIA_EDIT));
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();

  const [initialForm, setInitialForm] = useState({
    name: name || '',
    text: ticker_tape?.text || '',
    color: ticker_tape?.color || '#000000',
    font: ticker_tape?.font || tickerTapeTypes?.fonts[0].value,
    speed: ticker_tape?.speed || 50,
    scrollDirection:
      ticker_tape?.direction || tickerTapeTypes?.scrollDirection[0].value,
    mediaFolderId: folder_id,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    text: Yup.string().required('Text is required'),
    color: Yup.string()
      .matches(
        RegexOptions.HEX_COLOR,
        'Color must be a valid hexadecimal color',
      )
      .required('Color is a required field'),
    speed: Yup.string()
      .matches(
        RegexOptions.ZERO_TO_HUNDRED_PERCENT,
        'Please enter a number between 1 and 100',
      )
      .required('Speed is required'),
  });

  const onSubmit = values => {
    setSubmitting(true);

    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      setSubmitting(false);
      return;
    }

    const mediaData = new FormData();

    mediaData.append('media[type]', MediaItemTypes.TICKER_TAPE_MEDIA_ITEM);
    mediaData.append('media[name]', values.name);
    mediaData.append('media[text]', values.text);
    mediaData.append('media[color]', values.color);
    mediaData.append('media[font]', values.font);
    mediaData.append('media[speed]', values.speed);
    mediaData.append('media[direction]', values.scrollDirection);

    if (id && values.mediaFolderId >= 0) {
      mediaData.append(
        'media[media_folder_id]',
        values.mediaFolderId === 0 ? '' : values.mediaFolderId,
      );
    } else if (folder_info) {
      mediaData.append('media[media_folder_id]', folder_info.id);
    }

    const promise = dispatch(
      fetchData(id ? reverse(Api.MEDIA_DETAIL, { id }) : Api.MEDIA_POST, {
        method: id ? 'PATCH' : 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        setSubmitting(false);
        dispatch(mediaDeleted(id));
        if (result.media) {
          if (
            (!values.mediaFolderId && values.mediaFolderId !== 0) ||
            values.mediaFolderId === folder_id
          ) {
            dispatch(addMediaAction(result));
          }
        }

        if (values.mediaFolderId >= 0 && values.mediaFolderId !== folder_id) {
          dispatch(
            setInfoBar({
              message: 'The media item has been repositioned',
              action: [
                {
                  handle: () => {
                    dispatch(setInfoBarShow({ show: false }));
                    history.push({
                      pathname:
                        values.mediaFolderId === 0
                          ? Paths.MEDIA
                          : reverse(Paths.MEDIA_FOLDER, {
                              folder_id: values.mediaFolderId,
                            }),
                    });
                  },
                  text: 'Go to folder',
                },
              ],
              timeout: 5000,
              state: 'check',
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: result.message,
              timeout: 5000,
              state: 'check',
            }),
          );
        }
      })
      .catch(() => {
        setSubmitting(false);
        if (id) {
          dispatch(
            setInfoBar({
              message: 'Could not update media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'Could not add media item',
              state: InfoBarState.ERROR,
              timeout: 5000,
            }),
          );
        }
      });

    dispatch(popupActionClear());
  };

  // TODO: Activate useEffect below once API is connected
  useEffect(() => {
    dispatch(fetchMediaValueTypesAction('ticker_tape'));
  }, [dispatch]);

  useEffect(() => {
    setInitialForm({
      name: name || '',
      text: ticker_tape?.text || '',
      color: ticker_tape?.color || '#000000',
      font: ticker_tape?.font || tickerTapeTypes?.fonts[0].value,
      speed: ticker_tape?.speed || 50,
      scrollDirection:
        ticker_tape?.direction || tickerTapeTypes?.scrollDirection[0].value,
      mediaFolderId: folder_id,
    });
  }, [tickerTapeTypes, ticker_tape, name, folder_id]);

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        handleChange,
        values,
        errors,
        setFieldValue,
        handleSubmit,
        touched,
      }) => (
        <PopupStickyForm onSubmit={handleSubmit}>
          <PopupStickyHeader>
            <fieldset>
              <FormBlock flatten>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="New ticker tape"
                  value={values.name}
                  error={touched.name && errors.name}
                  onChange={handleChange}
                  fontSize="large"
                  suffix={<Icon name="edit" />}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>
            </fieldset>
          </PopupStickyHeader>

          <PopupStickyMain>
            <fieldset>
              <legend>Content</legend>
              <FormBlock flatten>
                <Input
                  label="Text"
                  type="text"
                  name="text"
                  id="text"
                  scheme="white"
                  placeholder="Text"
                  value={values.text}
                  onChange={handleChange}
                  disabled={!hasMediaEditRights}
                  error={touched.text && errors.text}
                />
              </FormBlock>
            </fieldset>

            <fieldset>
              <legend>Styling and format</legend>
              <FormBlock flatten>
                <ColorPickerInput
                  label="Color"
                  name="color"
                  id="color"
                  placeholder="Color"
                  value={values.color}
                  onChange={setFieldValue}
                  disabled={!hasMediaEditRights}
                />
                <Dropdown
                  label="Font"
                  name="font"
                  id="font"
                  value={values.font}
                  onChange={setFieldValue}
                  placeholder="Select a font"
                  hasEmptyOption={false}
                  values={tickerTapeTypes?.fonts}
                  loading={!tickerTapeTypes?.fonts}
                  disabled={!hasMediaEditRights}
                  sortOptions
                />
              </FormBlock>
              <FormBlock>
                <FormMessage
                  errorMessage={touched.color && errors.color}
                  belowField
                />
                <FormMessage
                  errorMessage={touched.font && errors.font}
                  belowField
                />
              </FormBlock>
              <FormBlock flatten>
                <Input
                  label="Speed"
                  type="number"
                  name="speed"
                  id="text"
                  scheme="white"
                  suffix="%"
                  placeholder="Speed (1 - 100)"
                  maxLength={3}
                  value={values.speed}
                  onChange={handleChange}
                  disabled={!hasMediaEditRights}
                />
                <Dropdown
                  label="Scroll direction"
                  name="scrollDirection"
                  id="scrollDirection"
                  value={values.scrollDirection}
                  onChange={setFieldValue}
                  error={touched.scrollDirection && errors.scrollDirection}
                  placeholder="Select scroll direction"
                  hasEmptyOption={false}
                  values={tickerTapeTypes?.scrollDirection}
                  loading={!tickerTapeTypes?.scrollDirection}
                  disabled={!hasMediaEditRights}
                />
              </FormBlock>
              <FormBlock flatten>
                <FormMessage
                  errorMessage={touched.speed && errors.speed}
                  belowField
                />
              </FormBlock>
            </fieldset>

            {id && (
              <>
                <MediaPreviewDetails id={id} created_at={created_at} />
                <MediaPlacementFieldset
                  name="mediaFolderId"
                  setFieldValue={setFieldValue}
                  folder_id={folder_id}
                  disabled={!hasMediaEditRights}
                />
              </>
            )}

            {shows && shows.length > 0 && (
              <MediaShowsOverviewFieldset shows={shows} />
            )}
          </PopupStickyMain>
          <PopupStickyFooter>
            <FormBlock hasInlineChildren flatten>
              {(hasMediaEditRights && (
                <Button
                  tag="button"
                  type="button"
                  size="medium"
                  text="Delete"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    dispatch(
                      setInfoBar({
                        message:
                          'Are you sure you want to delete this media item?',
                        action: [
                          {
                            text: 'Yes, Delete',
                            type: 'button',
                            color: 'blue',
                            handle: () =>
                              dispatch(deleteMediaAction({ id, shows })),
                          },
                          {
                            text: 'Cancel',
                            type: 'link',
                            handle: () => dispatch(infoBarHide()),
                          },
                        ],
                        state: InfoBarState.ERROR,
                      }),
                    );
                  }}
                  disabled={isSubmitting}
                />
              )) || (
                <Button
                  tag="button"
                  type="submit"
                  size="medium"
                  text="Cancel"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    dispatch(popupActionClear());
                    if (infoBar.show) {
                      dispatch(infoBarHide());
                    }
                  }}
                  disabled={isSubmitting}
                />
              )}

              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Save and close"
                disabled={!hasMediaEditRights || isSubmitting}
              />
            </FormBlock>
          </PopupStickyFooter>
        </PopupStickyForm>
      )}
    </Formik>
  );
};

MediaTickerTape.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  tickerTape: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    font: PropTypes.string,
    speed: PropTypes.number,
    scrollDirection: PropTypes.oneOf(['leftToRight', 'rightToLeft']),
  }),
  folder_id: PropTypes.number,
};

MediaTickerTape.defaultProps = {
  id: null,
  name: '',
  tickerTape: null,
  folder_id: null,
};

export default MediaTickerTape;
