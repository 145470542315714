import React, { useEffect, useState } from 'react';

import OverviewList from 'components/overviewList/OverviewList';
import Status from 'components/status/Status';
import { OverviewListItemTag } from 'components/overviewList/OverviewListItem.enum';
import DeckEmpty from 'components/deck/DeckEmpty';

import { TriggerStatus } from 'components/popup/inputs/inputSignals/InputSignalEdit.types';

import { InputSignalAttachedTriggersOverviewProps } from './InputSignalAttachedTriggersOverview.types';

const InputSignalAttachedTriggersOverview: React.FC<InputSignalAttachedTriggersOverviewProps> = ({
  isLoading,
  triggers = [],
}) => {
  const [loading, setLoading] = useState(isLoading);
  const [loadingLength, setLoadingLength] = useState(5);
  const overviewHeaders = [
    {
      key: 'Status',
      content: '',
      width: '25px',
    },
    {
      key: 'Name',
      content: 'Name',
    },
  ];
  const overviewData = triggers.map(trigger => ({
    id: `${trigger.id}`,
    key: `${trigger.id}`,
    items: [
      {
        key: '1',
        width: '25px',
        content: (
          <Status
            isActive={trigger.status === TriggerStatus.ONLINE}
            hasText={false}
            title={
              trigger.status === TriggerStatus.ONLINE
                ? TriggerStatus.ONLINE
                : TriggerStatus.OFFLINE
            }
          />
        ),
      },
      {
        key: '2',
        content: trigger.name,
      },
    ],
    tag: OverviewListItemTag.DIV,
  }));

  useEffect(() => {
    if (triggers.length > 0) {
      setLoadingLength(triggers.length);
    }
  }, [triggers]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <fieldset>
      <div>
        <legend>Used in</legend>
        <p>This input signal is used in:</p>
      </div>

      {loading || triggers?.length > 0 ? (
        <OverviewList
          headers={overviewHeaders}
          hideHeaders
          content={overviewData}
          loading={loading}
          loadingLength={loadingLength}
          cardProps={{
            highlightOnHover: false,
            interactive: false,
          }}
        />
      ) : (
        <DeckEmpty title="No triggers found" height={150} />
      )}
    </fieldset>
  );
};

export default InputSignalAttachedTriggersOverview;
