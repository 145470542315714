import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { RRule } from 'rrule';
import moment from 'moment-timezone';

import FormBlock from 'components/form/block/FormBlock';
import Dropdown from 'components/form/dropdown/Dropdown';
import DropdownMultiple from 'components/form/dropdown-multiple/DropdownMultiple';
import Input from 'components/form/input/Input';
import DateInput from 'components/form/datetime/DateInput';
import { parseISO } from 'date-fns';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRight from 'constants/UserRight.enum';
import {
  RecurringOptions,
  MonthlyIntervalOptions,
  dayList,
} from './Recurring.options';

const Recurring = ({
  name,
  value,
  values,
  errors,
  touched,
  setFieldValue,
  onChange,
  startDate,
  pending_approval,
  pending_changes,
}) => {
  const dispatch = useDispatch();
  const hasEventEditRights = dispatch(hasUserRights(UserRight.EVENTS_EDIT));

  const weekDayIdsToKeyValueArray = values => {
    if (!values) return [];

    if (Array.isArray(values) && values.filter(val => val.id).length > 0) {
      return values;
    }

    return values.map(val => ({
      id: val,
      name: dayList.map(values => values.value === val)[0].label,
    }));
  };

  const monthlyDayIdsToKeyValueArray = values => {
    if (!values) return [];

    if (Array.isArray(values) && values.filter(val => val.id).length > 0) {
      return values;
    }

    return values.map(val => ({
      id: val,
      name: val.toString(),
    }));
  };

  const [rrule, setRrule] = useState(value);
  const [weeklyDays, setWeeklyDays] = useState(
    weekDayIdsToKeyValueArray(values.weeklyDays),
  );
  const [monthlyDays, setMonthlyDays] = useState(
    monthlyDayIdsToKeyValueArray(values.monthlyDays),
  );
  const rruleToObject = rrule => {
    if (rrule === undefined || rrule === null) return {};

    const result = {};
    const rrulePairs = rrule.split(';');
    rrulePairs.forEach(pair => {
      const pairSplit = pair.split('=');
      result[pairSplit[0]] = decodeURIComponent(pairSplit[1] || '');
    });

    return result;
  };

  const currentRruleObject = rruleToObject(rrule);
  const changedRruleObject = rruleToObject(pending_changes?.rrule);

  const rruleChanged = (currentRruleObject, changedRruleObject) => {
    if (currentRruleObject === undefined && changedRruleObject === undefined)
      return false;

    const untilChanged = currentRruleObject.UNTIL !== changedRruleObject.UNTIL;
    const freqChanged = currentRruleObject.FREQ !== changedRruleObject.FREQ;
    const bySetPosChanged =
      currentRruleObject.BYSETPOS !== changedRruleObject.BYSETPOS;
    const byDayChanged = currentRruleObject.BYDAY !== changedRruleObject.BYDAY;
    const byMonthDayChanged =
      currentRruleObject.BYMONTHDAY !== changedRruleObject.BYMONTHDAY;
    const intervalChanged =
      currentRruleObject.INTERVAL !== changedRruleObject.INTERVAL;

    return {
      UNTIL: untilChanged,
      FREQ: freqChanged,
      INTERVAL: intervalChanged,
      BYSETPOS: bySetPosChanged,
      BYDAY: byDayChanged,
      BYMONTHDAY: byMonthDayChanged,
    };
  };

  const monthDaysStringToKeyValueArray = monthDaysString => {
    const result = [];

    if (!monthDaysString) {
      return result;
    }

    const monthDays = monthDaysString.split(',');

    monthDays.forEach(day => {
      result.push({ label: Number(day), value: Number(day) });
    });

    return result;
  };

  const weekDaysStringToKeyValueArray = weekDaysString => {
    if (!weekDaysString) return [];
    const weekDays = weekDaysString.split(',');
    const result = [];

    weekDays.forEach(day => {
      const index = dayList.findIndex(
        x => x.label.toUpperCase().substring(0, 2) === day,
      );
      const dayName = dayList[index].label;

      result.push({ name: dayName, id: index });
    });

    return result;
  };

  // when any of the rule related fields are changed
  useEffect(() => {
    setWeeklyDays(weekDayIdsToKeyValueArray(values.weeklyDays));
    setMonthlyDays(monthlyDayIdsToKeyValueArray(values.monthlyDays));

    const keyValueToValue = value => {
      if (Array.isArray(value) && value.some(val => val.id >= 0)) {
        return value.map(val => val.id);
      }

      return value;
    };

    if (values.repeat !== RecurringOptions.NO_REPEAT) {
      const ruleOptions = {};

      if (!moment.isMoment(values.dateUntil) || values.dateUntil.isValid()) {
        ruleOptions.until =
          values.dateUntil !== 'Invalid date'
            ? new Date(values.dateUntil)
            : values.dateUntil;
      }

      switch (values.repeat) {
        default:
        case RecurringOptions.DAILY:
          ruleOptions.freq = RRule.DAILY;
          ruleOptions.interval = values.dailyInterval;
          break;

        case RecurringOptions.WEEKLY:
          ruleOptions.freq = RRule.WEEKLY;
          ruleOptions.byweekday = Array.isArray(values.weeklyDays)
            ? keyValueToValue(values.weeklyDays)
            : [];
          break;

        case RecurringOptions.MONTHLY_NUMBER:
          ruleOptions.freq = RRule.MONTHLY;
          ruleOptions.bymonthday = Array.isArray(values.monthlyDays)
            ? keyValueToValue(values.monthlyDays)
            : [];
          break;

        case RecurringOptions.MONTHLY_DAY:
          ruleOptions.freq = RRule.MONTHLY;
          ruleOptions.bysetpos = values.monthlyInterval;
          ruleOptions.byweekday = values.monthlyIntervalDay;
          break;
      }

      const rule = new RRule(ruleOptions);

      setRrule(rule.toString().replace('RRULE:', ''));
    } else {
      setRrule('');
    }
  }, [startDate, values]);

  // when the rrule is changed
  useEffect(() => {
    onChange(name, rrule);
  }, [name, rrule, onChange]);

  const rruleIsChangedByDayValue = changedRruleObject.BYDAY;
  const rruleIsChangedByMonthDayValue = changedRruleObject.BYMONTHDAY;
  const rruleIsChangedFreqValue =
    changedRruleObject.FREQ && changedRruleObject.FREQ.toLowerCase();
  const rruleIsChangedIntervalValue = changedRruleObject.INTERVAL;
  const rruleIsChangedBySetPosValue = changedRruleObject.BYSETPOS;
  const rruleIsChangedUntilValue =
    changedRruleObject.UNTIL && parseISO(changedRruleObject.UNTIL);
  const rruleIsChanged = pending_changes?.rrule !== undefined;
  const rruleBySetPosIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).BYSETPOS === true;
  const rruleByDayIsChanged =
    rruleIsChanged && rruleChanged(rrule, changedRruleObject).BYDAY === true;
  const rruleByMonthDayIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).BYMONTHDAY === true;
  const rruleFreqIsChanged =
    (rruleIsChanged &&
      rruleChanged(currentRruleObject, changedRruleObject).FREQ === true) ||
    (rruleIsChanged &&
      values.repeat === 'monthly_day' &&
      rruleIsChangedByDayValue === undefined &&
      rruleIsChangedBySetPosValue === undefined) ||
    (rruleIsChanged &&
      values.repeat === 'monthly_number' &&
      rruleIsChangedByDayValue !== undefined &&
      rruleIsChangedBySetPosValue !== undefined);
  const rruleIntervalIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).INTERVAL === true;
  const rruleUntilIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).UNTIL === true;

  const freqOptions = [
    { label: 'Not repeating', value: RecurringOptions.NO_REPEAT },
    { label: 'Daily', value: RecurringOptions.DAILY },
    { label: 'Weekly', value: RecurringOptions.WEEKLY },
    { label: 'Monthly by day', value: RecurringOptions.MONTHLY_DAY },
    {
      label: 'Monthly by number',
      value: RecurringOptions.MONTHLY_NUMBER,
    },
  ];

  const isRecurring =
    (values.repeat !== RecurringOptions.NO_REPEAT &&
      pending_changes?.rrule !== null) ||
    rruleIsChangedUntilValue;

  return (
    <>
      <FormBlock ignoreSplit flatten={!isRecurring} isStart>
        <Dropdown
          label="Repeat"
          name="repeat"
          id="repeat"
          values={freqOptions}
          width={200}
          value={
            (pending_approval &&
              rruleFreqIsChanged &&
              rruleIsChangedFreqValue !== 'monthly' &&
              rruleIsChangedFreqValue) ||
            (pending_approval &&
              rruleFreqIsChanged &&
              rruleIsChangedBySetPosValue !== undefined &&
              rruleIsChangedFreqValue &&
              `${rruleIsChangedFreqValue}_day`) ||
            (pending_approval &&
              rruleFreqIsChanged &&
              rruleIsChangedBySetPosValue === undefined &&
              rruleIsChangedFreqValue &&
              `${rruleIsChangedFreqValue}_number`) ||
            (pending_approval &&
              rruleFreqIsChanged &&
              !rruleIsChangedFreqValue &&
              RecurringOptions.NO_REPEAT) ||
            values.repeat
          }
          onChange={(func, value) => {
            setFieldValue('repeat', value);
          }}
          isChanged={
            (pending_approval &&
              pending_changes &&
              !!pending_changes.rrule &&
              rruleFreqIsChanged) ||
            (pending_approval &&
              pending_changes &&
              pending_changes.rrule === null) ||
            (pending_approval && pending_changes === undefined)
          }
          hasEmptyOption={false}
          disabled={pending_approval || !hasEventEditRights}
        />

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedFreqValue === 'daily'
              : values.repeat === RecurringOptions.DAILY
          }
          timeout={{
            enter: 300,
          }}
          classNames="toggle"
          unmountOnExit
        >
          <Input
            name="dailyInterval"
            id="dailyInterval"
            type="number"
            label="Every"
            suffix="day(s)"
            width={120}
            scheme="white"
            value={
              (pending_approval &&
                rruleIntervalIsChanged &&
                Number(rruleIsChangedIntervalValue)) ||
              values.dailyInterval
            }
            min={1}
            onChange={e => {
              setFieldValue('dailyInterval', e.target.value);
            }}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'daily' &&
                rruleIntervalIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
            error={touched.dailyInterval && errors.dailyInterval}
          />
        </CSSTransition>

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedFreqValue === 'weekly'
              : values.repeat === RecurringOptions.WEEKLY
          }
          timeout={{
            enter: 300,
          }}
          classNames="toggle"
          unmountOnExit
        >
          <DropdownMultiple
            name="weeklyDays"
            id="weeklyDays"
            label="Which days of the week"
            scheme="white"
            width={300}
            value={
              (pending_approval &&
                pending_changes &&
                rruleByDayIsChanged &&
                weekDaysStringToKeyValueArray(rruleIsChangedByDayValue)) ||
              weeklyDays
            }
            onChange={v => {
              setFieldValue(
                'weeklyDays',
                [].slice.call(v).map(option => ({
                  id: option.value,
                  name: option.label,
                })),
              );
            }}
            values={dayList}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'weekly' &&
                rruleByDayIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
            placeholder="Select which days of the week"
            error={touched.weeklyDays && errors.weeklyDays}
            sortOptions
            sortByValue
          />
        </CSSTransition>

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedByDayValue === undefined &&
                rruleIsChangedBySetPosValue === undefined &&
                rruleIsChangedIntervalValue === undefined &&
                rruleIsChangedFreqValue === 'monthly'
              : values.repeat === RecurringOptions.MONTHLY_NUMBER
          }
          timeout={{
            enter: 300,
          }}
          classNames="toggle"
          unmountOnExit
        >
          <DropdownMultiple
            name="monthlyDays"
            id="monthlyDays"
            label="Which days of the month"
            scheme="white"
            width={300}
            value={
              (pending_approval &&
                pending_changes &&
                rruleByMonthDayIsChanged &&
                monthDaysStringToKeyValueArray(
                  rruleIsChangedByMonthDayValue,
                )) ||
              monthlyDays
            }
            onChange={v => {
              setFieldValue(
                'monthlyDays',
                [].slice.call(v).map(option => ({
                  id: option.value,
                  name: option.label,
                })),
              );
            }}
            values={[...Array(31).keys()].map(d => {
              return { value: d + 1, label: d + 1 };
            })}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'monthly' &&
                rruleByMonthDayIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
            placeholder="Select which days of the month"
            error={touched.monthlyDays && errors.monthlyDays}
            sortOptions
          />
        </CSSTransition>

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedByDayValue !== undefined &&
                rruleIsChangedBySetPosValue !== undefined
              : values.repeat === RecurringOptions.MONTHLY_DAY
          }
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <Dropdown
            label="Every"
            name="monthlyInterval"
            id="monthlyInterval"
            values={[
              { label: 'First', value: MonthlyIntervalOptions.FIRST },
              { label: 'Second', value: MonthlyIntervalOptions.SECOND },
              { label: 'Third', value: MonthlyIntervalOptions.THIRD },
              { label: 'Fourth', value: MonthlyIntervalOptions.FOURTH },
              {
                label: 'Last',
                value: MonthlyIntervalOptions.LAST,
              },
            ]}
            width={170}
            value={
              (pending_approval &&
                rruleBySetPosIsChanged &&
                Number(rruleIsChangedBySetPosValue)) ||
              values.monthlyInterval
            }
            onChange={(func, value) => {
              setFieldValue('monthlyInterval', value);
            }}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'monthly' &&
                rruleBySetPosIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            hasEmptyOption={false}
            disabled={pending_approval || !hasEventEditRights}
            placeholder="Select every"
            error={touched.monthlyInterval && errors.monthlyInterval}
          />
        </CSSTransition>
        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedByDayValue !== undefined &&
                rruleIsChangedBySetPosValue !== undefined
              : values.repeat === RecurringOptions.MONTHLY_DAY
          }
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <Dropdown
            label="Day of the month"
            name="monthlyIntervalDay"
            id="monthlyIntervalDay"
            values={dayList}
            width={170}
            value={
              (pending_approval &&
                rruleByDayIsChanged &&
                dayList.find(
                  x =>
                    x.label.toUpperCase().substring(0, 2) ===
                    rruleIsChangedByDayValue,
                )) ||
              values.monthlyIntervalDay !== ''
                ? Number(values.monthlyIntervalDay)
                : values.monthlyIntervalDay
            }
            onChange={(func, value) => {
              setFieldValue('monthlyIntervalDay', value);
            }}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'monthly' &&
                rruleByDayIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            hasEmptyOption={false}
            disabled={pending_approval || !hasEventEditRights}
            placeholder="Select day of the month"
            error={touched.monthlyIntervalDay && errors.monthlyIntervalDay}
          />
        </CSSTransition>
      </FormBlock>

      <CSSTransition
        in={isRecurring}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <>
          <FormBlock>
            <DateInput
              name="dateUntil"
              id="dateUntil"
              label="Repeat until"
              onChange={setFieldValue}
              value={
                (pending_approval &&
                  rruleUntilIsChanged &&
                  rruleIsChangedUntilValue) ||
                values.dateUntil
              }
              minDate={moment(0).format()}
              error={touched.dateUntil && errors.dateUntil}
              isChanged={
                (pending_approval && rruleUntilIsChanged) ||
                (pending_approval && pending_changes === undefined)
              }
              disabled={pending_approval || !hasEventEditRights}
            />
          </FormBlock>
          {errors.dateUntil && <FormBlock flatten error={errors.dateUntil} />}
        </>
      </CSSTransition>
    </>
  );
};

export default Recurring;
