import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import OverviewList from 'components/overviewList/OverviewList';
import Status from 'components/status/Status';
import DragSwitch from 'components/form/dragSwitch/DragSwitch';
import { OverviewListItemTag } from 'components/overviewList/OverviewListItem.enum';
import DeckEmpty from 'components/deck/DeckEmpty';

import UserRight from 'constants/UserRight.enum';

import { toggleActivateAttachedInputSignalsAction } from 'store/inputSignals/inputDevices/inputDevicesActions';
import { hasUserRights } from 'store/auth/hasUserRights';

import { InputSignalsOverviewFieldsetProps } from './InputSignalsOverviewFieldset.types';

const InputSignalsOverviewFieldset: React.FC<InputSignalsOverviewFieldsetProps> = ({
  isLoading,
  id,
  input_signals = [],
  active,
  isDisabled,
  setSubmitting,
}) => {
  const dispatch = useDispatch();
  const hasInputEditRights = dispatch(hasUserRights(UserRight.INPUT_EDIT));
  const [loading, setLoading] = useState(isLoading);
  const [loadingLength, setLoadingLength] = useState(5);
  const overviewHeaders = [
    {
      key: 'Status',
      content: '',
      width: '25px',
    },
    {
      key: 'Name',
      content: 'Name',
    },
  ];
  const overviewData = input_signals.map(input_signal => ({
    id: `${input_signal.id}`,
    key: `${input_signal.id}`,
    items: [
      {
        key: '1',
        width: '25px',
        content: (
          <Status
            isActive={input_signal.active}
            hasText={false}
            title={input_signal.active ? 'Online' : 'Offline'}
          />
        ),
      },
      {
        key: '2',
        content: input_signal.name,
      },
    ],
    tag: OverviewListItemTag.DIV,
  }));

  useEffect(() => {
    if (input_signals.length > 0) {
      setLoadingLength(input_signals.length);
    }
  }, [input_signals]);

  useEffect(() => {
     setLoading(isLoading);
  }, [isLoading]);

  return (
    <fieldset>
      {loading || input_signals?.length > 0 ? (
        <>
          <div className="input-device-edit__input-signals-header-layout">
            <legend className="input-device-edit__input-signals-header-layout__header">
              Attached input signals
            </legend>
            <p className="input-device-edit__input-signals-header-layout__paragraph">
              This device contains the following input signals:
            </p>
            {id && (
              <DragSwitch
                className="input-device-edit__input-signals-header-layout__drag-switch"
                id="activateInputSignals"
                name="activateInputSignals"
                labelOn="Active"
                labelOff="Disabled"
                isDisabled={
                  (!loading && input_signals?.length === 0) || !hasInputEditRights || isDisabled
                }
                isChecked={active}
                onChange={() => {
                  dispatch(
                    toggleActivateAttachedInputSignalsAction(
                      id,
                      active,
                      setSubmitting,
                    ),
                  );
                }}
              />
            )}
          </div>
          <OverviewList
            headers={overviewHeaders}
            content={overviewData}
            loading={loading}
            loadingLength={loadingLength}
            cardProps={{
              highlightOnHover: false,
              interactive: false,
            }}
          />
        </>
      ) : (
        <>
          <legend className="input-device-edit__input-signals-header-layout__header">
            Attached input signals
          </legend>
          <DeckEmpty title="No input signals found" />
        </>
      )}
    </fieldset>
  );
};

export default InputSignalsOverviewFieldset;
