export enum MediaItemTypes {
  URL_MEDIA_ITEM = 'UrlMediaItem',
  UPLOAD_MEDIA_ITEM = 'UploadMediaItem',
  JACKPOT_MEDIA_ITEM = 'JackpotMediaItem',
  RICH_TEXT_MEDIA_ITEM = 'RichTextMediaItem',
  TICKER_TAPE_MEDIA_ITEM = 'TickerTapeMediaItem',
  WEBPACKAGE_ITEM = 'WebpackageMediaItem',
}

export default MediaItemTypes;
