import React, { FC } from 'react';
import classNames from 'classnames';
import Skeleton from 'components/skeleton/Skeleton';
import { SkeletonType } from 'components/skeleton/Skeleton.enum';

import './Icon.scss';
import { ReactComponent as ArrowRight } from 'assets/icons/icon_arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/icon_arrow-left.svg';
import { ReactComponent as Calendar } from 'assets/icons/icon_calendar.svg';
import { ReactComponent as Check } from 'assets/icons/icon_check.svg';
import { ReactComponent as Chevron } from 'assets/icons/icon_chevron.svg';
import { ReactComponent as Circle } from 'assets/icons/icon_circle.svg';
import { ReactComponent as Cross } from 'assets/icons/icon_cross.svg';
import { ReactComponent as Download } from 'assets/icons/icon_download.svg';
import { ReactComponent as Edit } from 'assets/icons/icon_edit.svg';
import { ReactComponent as Image } from 'assets/icons/icon_image.svg';
import { ReactComponent as ImageSmall } from 'assets/icons/icon_image_small.svg';
import { ReactComponent as Plus } from 'assets/icons/icon_plus.svg';
import { ReactComponent as Repeat } from 'assets/icons/icon_repeat.svg';
import { ReactComponent as Url } from 'assets/icons/icon_url.svg';
import { ReactComponent as Video } from 'assets/icons/icon_video.svg';
import { ReactComponent as Warning } from 'assets/icons/icon_warning.svg';
import { ReactComponent as TwoFactorAuthentication } from 'assets/icons/icon_two-factor-authentication.svg';
import { ReactComponent as Folder } from 'assets/icons/icon_folder.svg';
import { ReactComponent as MoreOptions } from 'assets/icons/icon_more_options.svg';
import { ReactComponent as Account } from 'assets/icons/icon_account.svg';
import { ReactComponent as Logout } from 'assets/icons/icon_logout.svg';
import { ReactComponent as Settings } from 'assets/icons/icon_settings.svg';
import { ReactComponent as Upload } from 'assets/icons/icon_upload.svg';
import { ReactComponent as Jackpot } from 'assets/icons/icon_jackpot.svg';
import { ReactComponent as RTE } from 'assets/icons/icon_rte.svg';
import { ReactComponent as TickerTape } from 'assets/icons/icon_ticker-tape.svg';
import { ReactComponent as Drag } from 'assets/icons/icon_drag.svg';
import { ReactComponent as Time } from 'assets/icons/icon_time.svg';
import { ReactComponent as Html } from 'assets/icons/icon_html.svg';
import { ReactComponent as PriorityLow } from 'assets/icons/icon_prio_low.svg';
import { ReactComponent as PriorityMedium } from 'assets/icons/icon_prio_medium.svg';
import { ReactComponent as PriorityHigh } from 'assets/icons/icon_prio_high.svg';
import { ReactComponent as Webpackage } from 'assets/icons/icon_webpackage.svg';
import { ReactComponent as Tool } from 'assets/icons/icon_tool.svg';
import { ReactComponent as User } from 'assets/icons/icon_user.svg';
import { ReactComponent as Lock } from 'assets/icons/icon_lock.svg';
import { ReactComponent as Access } from 'assets/icons/icon_access.svg';
import { ReactComponent as Delete } from 'assets/icons/icon_delete.svg';
import { ReactComponent as Info } from 'assets/icons/icon_info.svg';
import { ReactComponent as Stop } from 'assets/icons/icon_stop.svg';
import { ReactComponent as StopWatch } from 'assets/icons/icon_stopwatch.svg';
import { ReactComponent as Target } from 'assets/icons/icon_target.svg';

import { IconList, IconProps } from './Icon.types';

export const iconList: IconList = {
  'arrow-right': <ArrowRight />,
  'arrow-left': <ArrowLeft />,
  calendar: <Calendar />,
  check: <Check />,
  chevron: <Chevron />,
  circle: <Circle />,
  cross: <Cross />,
  download: <Download />,
  edit: <Edit />,
  image: <Image />,
  imageSmall: <ImageSmall />,
  plus: <Plus />,
  repeat: <Repeat />,
  url: <Url />,
  video: <Video />,
  warning: <Warning />,
  twoFactorAuthentication: <TwoFactorAuthentication />,
  folder: <Folder />,
  more_options: <MoreOptions />,
  logout: <Logout />,
  account: <Account />,
  settings: <Settings />,
  upload: <Upload />,
  jackpot: <Jackpot />,
  drag: <Drag />,
  rte: <RTE />,
  time: <Time />,
  tickerTape: <TickerTape />,
  html: <Html />,
  priorityLow: <PriorityLow />,
  priorityMedium: <PriorityMedium />,
  priorityHigh: <PriorityHigh />,
  webpackage: <Webpackage />,
  tool: <Tool />,
  user: <User />,
  lock: <Lock />,
  access: <Access />,
  delete: <Delete />,
  info: <Info />,
  stop: <Stop />,
  stopWatch: <StopWatch />,
  target: <Target />,
};

const Icon: FC<IconProps> = ({
  name,
  block = true,
  className,
  size,
  color,
  loading = false,
}) => {
  return (
    <span
      className={classNames('icon', className, {
        'icon--block': block,
        [`icon--size-${size}`]: size,
        [`icon--color-${color}`]: color,
      })}
    >
      {loading ? (
        <Skeleton type={SkeletonType.BUTTON_SQUARE} />
      ) : (
        <>
          {iconList[name] || <img src={`/icons/icon_${name}.svg`} alt={name} />}
        </>
      )}
    </span>
  );
};
export default Icon;
