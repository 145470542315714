import React, { ReactNode, FC, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Arrangement from 'components/popup/arrangement/Arrangement';
import Event from 'components/popup/event/Event';
import Trigger from 'components/popup/trigger/Trigger';
import MediaUrl from 'components/popup/media-url/MediaUrl';
import MediaUpload from 'components/popup/media-upload/MediaUpload';
import MediaPreview from 'components/popup/media-preview/MediaPreview';
import Info from 'components/popup/info/Info';
import Group from 'components/popup/group/Group';
import Pool from 'components/popup/pool/Pool';
import LogDetails from 'components/popup/log-details/LogDetails';
import Device from 'components/popup/device/Device';
import DeviceAdd from 'components/popup/device/DeviceAdd';
import DeviceEdit from 'components/popup/device/DeviceEdit';
import DeviceDetail from 'components/popup/device/DeviceDetail';
import { cancelUpdateMediaItems } from 'store/media/mediaActions';
import MediaFolder from 'components/popup/media-folder/MediaFolder';
import AccountSettingsTwoFactor from 'components/popup/account/AccountSettingsTwoFactor';
import AccountSettingsEmail from 'components/popup/account/AccountSettingsEmail';
import AccountSettingsPassword from 'components/popup/account/AccountSettingsPassword';
import AccountSettingsVerifyEmail from 'components/popup/account/AccountSettingsVerifyEmail';
import MediaJackpotValue from 'components/popup/media-jackpot-value/MediaJackpotValue';
import MediaRichText from 'components/popup/media-rich-text/MediaRichText';
import MediaTickerTape from 'components/popup/media-ticker-tape/MediaTickerTape';
import MediaWebpackage from 'components/popup/media-webpackage/MediaWebpackage';
import User from 'components/popup/management/User';
import UserRole from 'components/popup/management/userRole/UserRole';
import Location from 'components/popup/management/Location';
import InputDeviceAdd from 'components/popup/inputs/inputDevices/inputDeviceAdd';
import InputDeviceEdit from 'components/popup/inputs/inputDevices/inputDeviceEdit';
import InputSignalEdit from 'components/popup/inputs/inputSignals/InputSignalEdit';

import PopupTypes from 'constants/PopupTypes.enum';

import { popupActionClear } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';
import {
  accountSettingsTwoFactorResendEmailInitial,
  accountSettingsVerifyEmailPopupInactive,
} from 'store/account/accountSettingsActions';
import { RootState } from 'store/rootState';
import { deleteArrangementAction } from 'store/arrangements/arrangementsActions';

import {
  PopupProps,
  PopupStickyFooterProps,
  PopupStickyFormProps,
  PopupStickyHeaderProps,
  PopupStickyMainProps,
  PopupStickyWrapperProps,
} from './Popup.types';
import './Popup.scss';

export const Popup: FC<PopupProps> = ({
  popupType = 'default',
  size,
  position = 'top',
  hasBackground = true,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ newArrangement?: boolean }>();
  const type = useSelector((state: RootState) => state.popup.type);
  const data = useSelector((state: RootState) => state.popup.data);
  const infoBar = useSelector((state: RootState) => state.info.bar);

  const [content, setContent] = useState<ReactNode | null>(null);
  const [popupClosable, setPopupClosable] = useState(true);
  const [backgroundAction, setBackgroundAction] = useState<Function | null>(
    null,
  );
  const [popupSize, setPopupSize] = useState(size);
  const [popupStyle, setPopupStyle] = useState(popupType);
  const { state } = location;

  const closePopup = useCallback(() => {
    if (type === PopupTypes.ARRANGEMENT) {
      const arrangementId = data.id;
      if (state?.newArrangement) {
        dispatch(deleteArrangementAction(arrangementId, false, history));
      } else {
        history.push({
          state: {
            fromArrangement: undefined,
          },
        });
      }
    }

    if (type === PopupTypes.TRIGGER) {
      history.push({
        state: {
          fromTrigger: undefined,
          fromTriggerFormFormValues: undefined,
          newTrigger: undefined,
        },
      });
    }

    if (type === PopupTypes.EVENT) {
      history.push({
        state: {
          fromEvent: undefined,
          fromEventFormFormValues: undefined,
          newEvent: undefined,
        },
      });
    }


    if (type === PopupTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL) {
      dispatch(accountSettingsVerifyEmailPopupInactive());
    }
    dispatch(popupActionClear());

    // reset 'two_factor_resend_email' store state back to 'unsent' when closing ACCOUNT_SETTINGS_TWO_FACTOR popup
    if (type === PopupTypes.ACCOUNT_SETTINGS_TWO_FACTOR) {
      dispatch(accountSettingsTwoFactorResendEmailInitial());
    }

    // hide infoBar in case it is displayed
    if (type !== PopupTypes.INFO && infoBar.show && infoBar.state !== 'select') {
      dispatch(setInfoBarShow({ show: false }));
    }

    if (backgroundAction) {
      dispatch(backgroundAction);
    }
  }, [dispatch, infoBar, backgroundAction, setInfoBarShow, accountSettingsTwoFactorResendEmailInitial, accountSettingsVerifyEmailPopupInactive, history])

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      dispatch(setInfoBarShow({ show: false }));
      closePopup()
    }
  }, [closePopup, dispatch, setInfoBarShow])

  useEffect(() => {
    // clear popup state
    if (!state && !!type) {
      dispatch(popupActionClear())
    }
    // TODO: Fix exhaustive-deps warning
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    setPopupSize(undefined);

    switch (type) {
      case PopupTypes.ARRANGEMENT:
        setContent(<Arrangement {...data} />);
        setPopupStyle('sticky');
        setPopupSize('large');

        if (data.id) {
          setBackgroundAction(() => cancelUpdateMediaItems(data.id));
        }
        break;
      case PopupTypes.EVENT:
        setContent(<Event {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.TRIGGER:
        setContent(<Trigger {...data} />);
        setPopupSize('large');
        setPopupStyle('sticky');
        break;
      case PopupTypes.MEDIA_JACKPOT_VALUE:
        setContent(<MediaJackpotValue {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.MEDIA_TICKER_TAPE:
        setContent(<MediaTickerTape {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.MEDIA_UPLOAD:
        setContent(<MediaUpload {...data} />);
        break;
      case PopupTypes.MEDIA_URL:
        setContent(<MediaUrl {...data} />);
        break;
      case PopupTypes.MEDIA_PREVIEW:
        setContent(<MediaPreview {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.INFO:
        setContent(<Info>{data}</Info>);
        break;
      case PopupTypes.NOTIFICATION:
        setPopupClosable(false);
        setContent(<Info>{data}</Info>);
        break;
      case PopupTypes.DEVICE:
        setContent(<Device />);
        break;
      case PopupTypes.DEVICE_ADD:
        setContent(<DeviceAdd />);
        break;
      case PopupTypes.DEVICE_EDIT:
        setContent(<DeviceEdit {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.DEVICE_DETAIL:
        setContent(<DeviceDetail {...data} />);
        break;
      case PopupTypes.GROUP:
        setContent(<Group />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.MEDIA_FOLDER:
        setContent(<MediaFolder {...data} />);
        break;
      case PopupTypes.POOL_DETAIL:
        setContent(<Pool {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.LOG_DETAILS:
        setContent(<LogDetails {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.ACCOUNT_SETTINGS_TWO_FACTOR:
        setContent(<AccountSettingsTwoFactor {...data} />);
        break;
      case PopupTypes.ACCOUNT_SETTINGS_PASSWORD:
        setContent(<AccountSettingsPassword />);
        break;
      case PopupTypes.ACCOUNT_SETTINGS_EMAIL:
        setContent(<AccountSettingsEmail />);
        break;
      case PopupTypes.ACCOUNT_SETTINGS_VERIFY_EMAIL:
        setContent(<AccountSettingsVerifyEmail {...data} />);
        break;
      case PopupTypes.MEDIA_RICH_TEXT:
        setContent(<MediaRichText {...data} />);
        setPopupSize('large');
        setPopupStyle('sticky');
        break;
      case PopupTypes.MEDIA_WEBPACKAGE:
        setContent(<MediaWebpackage {...data} />);
        setPopupStyle('sticky');
        break;
      case PopupTypes.MANAGEMENT_USER:
        setContent(<User {...data} />);
        setPopupSize('large');
        setPopupStyle('sticky');
        break;
      case PopupTypes.MANAGEMENT_USER_ROLE:
        setContent(<UserRole {...data} />);
        setPopupSize('large');
        setPopupStyle('sticky');
        break;
      case PopupTypes.MANAGEMENT_LOCATION:
        setContent(<Location {...data} />);
        setPopupSize('large');
        setPopupStyle('sticky');
        break;
      case PopupTypes.INPUTS_INPUT_DEVICES_ADD:
        setContent(<InputDeviceAdd />);
        break;
      case PopupTypes.INPUTS_INPUT_DEVICE_EDIT:
        setContent(<InputDeviceEdit {...data} />);
        setPopupSize('large');
        setPopupStyle('sticky');
        break;
      case PopupTypes.INPUTS_INPUT_SIGNAL_EDIT:
        setContent(<InputSignalEdit {...data} />);
        setPopupSize('large');
        setPopupStyle('sticky');
        break;
      default:
        setContent(null);
        setPopupStyle('default');
    }



    if (type) {
      document.addEventListener('keydown', handleKeyDown)
      document.body.classList.add('no-scroll');
    } else {
      document.removeEventListener('keydown', handleKeyDown)
      document.body.classList.remove('no-scroll');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data]);

  return (
    <div className="popup">
      <CSSTransition
        in={!!content}
        timeout={{
          enter: 300,
          exit: 0,
        }}
        classNames="fade"
        unmountOnExit
      >
        <>
          <div
            className="popup__background"
            role="presentation"
            onClick={
              popupClosable ? () => closePopup() : e => e.preventDefault()
            }
          />
          <div
            className={classNames('popup__content', {
              'popup--default__content--background':
                hasBackground && popupStyle === 'default',
              [`popup__content--${popupSize}`]: popupSize,
              [`popup__content--position-${position}`]: position,
            })}
            data-cy="popupContent"
          >
            <div
              className={classNames('popup__wrapper', {
                'popup--default__wrapper': popupStyle === 'default',
                'popup--sticky__wrapper': popupStyle === 'sticky',
                'popup--default__wrapper--background':
                  hasBackground && popupStyle === 'default',
                'popup--sticky__wrapper--background':
                  hasBackground && popupStyle === 'sticky',
                [`popup__wrapper--${size}`]: size,
              })}
            >
              {popupClosable && (
                <div
                  className="popup__wrapper__close"
                  role="presentation"
                  onClick={() => closePopup()}
                />
              )}
              {content}
            </div>
          </div>
        </>
      </CSSTransition>
    </div>
  );
};

export const PopupStickyForm: FC<PopupStickyFormProps> = ({
  children,
  onSubmit,
  ...props
}) => {
  return (
    <form className="form popup--sticky__form" onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};

export const PopupStickyWrapper: FC<PopupStickyWrapperProps> = ({
  children,
  ...props
}) => {
  return (
    <form className="form popup--sticky__wrapper" {...props}>
      {children}
    </form>
  );
};

export const PopupStickyHeader: FC<PopupStickyHeaderProps> = ({ children }) => {
  return <div className="popup--sticky__header">{children}</div>;
};

export const PopupStickyMain: FC<PopupStickyMainProps> = ({
  children,
  padding = true,
}) => {
  return (
    <div
      className={classNames('popup--sticky__main', {
        'popup--sticky__main--padding': padding,
      })}
    >
      <>
        <div className="popup--sticky__main-shadow-top" />
        <div className="popup--sticky__main-inner">{children}</div>
        <div className="popup--sticky__main-shadow-bottom" />
      </>
    </div>
  );
};

export const PopupStickyFooter: FC<PopupStickyFooterProps> = ({ children }) => {
  return <div className="popup--sticky__footer">{children}</div>;
};

export default Popup;
