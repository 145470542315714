import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OverviewList from 'components/overviewList/OverviewList';
import Status from 'components/status/Status';
import DeckEmpty from 'components/deck/DeckEmpty';
import Ellipsis from 'components/typography/ellipsis/Ellipsis';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import InputDevicesFilter from 'components/inputs/inputDevices/filters/InputDevicesFilter';

import { RootState, InputDevice, InputDeviceConnection } from 'store/rootState';
import {
  fetchInputDevicesAction,
  openInputDeviceDetailsAction,
} from 'store/inputSignals/inputDevices/inputDevicesActions';
import { hasSuperAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import { popupActionSet } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';

import UserRight from 'constants/UserRight.enum';
import PopupTypes from 'constants/PopupTypes.enum';

import { MappedInputDevicesItemsType, MappedInputDevicesType } from './InputDevicesOverview.types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OverviewListHeader } from 'components/overviewList/OverviewList.types';

const InputDevicesOverview = () => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();
  const { request, inputDevices } = useSelector(
    (state: RootState) => state.inputs.inputDevices,
  );
  const { customerOverride } = useSelector((state: RootState) => state.superAdmin);

  const hasInputEditRights = dispatch(hasUserRights(UserRight.INPUT_EDIT));
  const isSuperAdmin = dispatch(hasSuperAdminRights())

  const [mappedInputDevices, setMappedInputDevices] = useState<
    MappedInputDevicesType[]
  >([]);

  useEffect(() => {
    dispatch(fetchInputDevicesAction({
      keyword: undefined,
      location_id: undefined
    }));
  }, [dispatch]);

  useEffect(() => {
    let devices = inputDevices;
    if (isSuperAdmin && customerOverride) {
      devices = devices?.filter((device) => device.customer_id === Number(customerOverride))
    }

    const deviceMapping = devices?.map((inputDevice: InputDevice) => {
      const { id, connection, name, serial_number, locations, customer } = inputDevice;

      return {
        key: id.toString(),
        id: id.toString(),
        items: [
          {
            key: `${id}_connection`,
            width: '25px',
            content: (
              <Status
                isActive={connection === InputDeviceConnection.ONLINE}
                hasText={false}
                title={connection}
              />
            ),
          },
          {
            key: `${id}_name`,
            content: name,
          },
          {
            key: `${id}_serial_number`,
            width: '240px',
            content: serial_number,
          },
          {
            key: `${id}_locations`,
            width: isSuperAdmin ? '300px' : '400px',
            content:
              locations.length > 0 ? (
                <Ellipsis
                  text={locations
                    .map(location => location.name)
                    .sort()
                    .join(', ')}
                />
              ) : (
                'Unassigned'
              ),
            status: locations.length > 0 ? 'active' : 'disabled',
          },
          isSuperAdmin ? {
            key: `${id}_customer`,
            width: '150px',
            content: customer ? (
              <Ellipsis text={customer} />
            ) : undefined
          } : undefined
          // TODO: Enable once status is implemented in API
          // {
          //   key: `${id}_status`,
          //   width: '200px',
          //   content: status,
          // },
        ].filter(Boolean) as MappedInputDevicesItemsType[],
        onClick: () => {
          dispatch(openInputDeviceDetailsAction(id));
        },
      };
    });

    setMappedInputDevices(deviceMapping);
  }, [dispatch, inputDevices, isSuperAdmin, customerOverride]);

  const overviewHeaders = [
    { key: 'connection', content: '', width: '25px' },
    { key: 'name', content: 'Name' },
    { key: 'serial_number', content: 'Serial', width: '240px' },
    { key: 'locations', content: 'Locations', width: isSuperAdmin ? '300px' : '400px' },
    isSuperAdmin ? { key: 'customer', content: 'Customer', width: '150px' } : undefined,
    // { key: 'status', content: 'Status', width: '200px' }, // TODO: Enable once status is implemented in API
  ].filter(Boolean) as OverviewListHeader[];

  return (
    <>
      <InputDevicesFilter localStorageItem="input-device-filters" />

      {!request && inputDevices && inputDevices.length === 0 ? (
        <DeckEmpty title="No input devices found" />
      ) : (
        <OverviewList
          headers={overviewHeaders}
          content={mappedInputDevices}
          loading={request}
          cardProps={{ highlightOnHover: true, interactive: true }}
        />
      )}

      {hasInputEditRights && (
        <div className="detail-button">
          <Button
            text={<Icon name="plus" size="medium" />}
            hoverText="Add input device"
            hoverTextInverse
            handler={() => {
              dispatch(popupActionSet(PopupTypes.INPUTS_INPUT_DEVICES_ADD, {}));
              dispatch(setInfoBarShow({ show: false }));
            }}
            shapeEdges="rounded"
          />
        </div>
      )}
    </>
  );
};

export default InputDevicesOverview;
