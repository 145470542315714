import fetchData from 'store/fetchData';
import { useSelector } from 'react-redux';
import { Paths, Api } from 'constants/Routes.enum';
import { reverse } from 'named-urls';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { History } from 'history';

import PopupTypes from 'constants/PopupTypes.enum';
import UserRights from 'constants/UserRight.enum';
import MediaItemTypes from 'constants/MediaItemTypes.enum';

import { RootState } from 'store/rootState';
import { setInfoBar } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import { hasSuperAdminRights, hasUserRights } from 'store/auth/hasUserRights';

import {
  AddMediaAction,
  DeleteMediaAction,
  FetchMediaAction,
  Media,
  MediaBreadcrumbs,
  MediaFilter,
  MediaFolderInfo,
  MediaItemSort,
  MediaMedia,
  MediaSort,
  MediaValueTypes,
} from './Media.types';
import MediaActionTypes, { MediaValueType } from './Media.enum';

export const mediaRequested = () => ({
  type: MediaActionTypes.MEDIA_REQUESTED,
});

export const mediaSucceeded = (media: Media) => ({
  type: MediaActionTypes.MEDIA_SUCCEEDED,
  payload: media,
});

export const mediaFailed = () => ({
  type: MediaActionTypes.MEDIA_FAILED,
});

export const mediaAdded = (media: MediaMedia) => ({
  type: MediaActionTypes.MEDIA_ADDED,
  payload: media,
});

export const mediaDeleted = (id: number) => ({
  type: MediaActionTypes.MEDIA_DELETED,
  payload: {
    media: [
      {
        id,
      },
    ],
  },
});

export const mediaFolderUpdated = (media: Media) => ({
  type: MediaActionTypes.MEDIA_FOLDER_UPDATED,
  payload: media,
});

export const mediaFolderAdded = (folderInfo: MediaFolderInfo) => ({
  type: MediaActionTypes.MEDIA_FOLDER_ADDED,
  payload: folderInfo,
});

export const mediaFolderDeleted = (id: number) => ({
  type: MediaActionTypes.MEDIA_FOLDER_DELETED,
  payload: {
    folder_info: {
      id,
    },
  },
});

export const mediaCleared = () => ({
  type: MediaActionTypes.MEDIA_CLEARED,
});

export const mediaFiltering = (filter: MediaFilter) => ({
  type: MediaActionTypes.MEDIA_FILTER,
  payload: filter,
});

export const mediaSorting = (sort: MediaSort) => ({
  type: MediaActionTypes.MEDIA_SORT,
  payload: sort,
});

export const mediaSelectable = (selectable: boolean) => ({
  type: MediaActionTypes.MEDIA_SELECTABLE,
  payload: selectable,
});

export const mediaTypeValuesSucceeded = (mediaTypeValues: MediaValueTypes) => ({
  type: MediaActionTypes.MEDIA_TYPE_VALUES_SUCCEEDED,
  payload: mediaTypeValues,
});

export const mediaTypeValuesFailed = () => ({
  type: MediaActionTypes.MEDIA_TYPE_VALUES_FAILED,
});

export const addMediaAction = ({
  message,
  media,
}: AddMediaAction): ThunkAction<
  void,
  RootState,
  undefined,
  AnyAction
> => async dispatch => {
  dispatch(mediaAdded(media));
  dispatch(popupActionClear());

  dispatch(setInfoBar({ message, timeout: 5000, state: InfoBarState.CHECK }));
};

export const deleteMediaAction = ({
  id,
  shows,
}: DeleteMediaAction): ThunkAction<
  void,
  RootState,
  undefined,
  AnyAction
> => async dispatch => {
  if (Array.isArray(shows) && shows.length) {
    dispatch(
      setInfoBar({
        message:
          "This media item couldn't be deleted because it's used in one or more shows",
        state: InfoBarState.ERROR,
        timeout: 5000,
      }),
    );
  } else {
    // call delete endpoint
    const promise = dispatch(
      fetchData(reverse(Api.MEDIA_DELETE, { id }), { method: 'DELETE' }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        const { message } = result;

        // media is deleted successfully - clear popup and show message
        dispatch(mediaDeleted(id));
        dispatch(popupActionClear());

        dispatch(
          setInfoBar({ message, timeout: 5000, state: InfoBarState.CHECK }),
        );
      })
      .catch((error: string) => {
        // media could not be deleted - show error
        console.error(error);
        dispatch(
          setInfoBar({
            message: error,
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  }
};

export const editMediaAction = (
  id: number,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const promise = dispatch(fetchData(reverse(Api.MEDIA_DETAIL, { id })));
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));

  promise
    .then(json => json.json())
    .then(({ media_item }) => {
      let popupType;
      switch (media_item.type) {
        default:
        case MediaItemTypes.UPLOAD_MEDIA_ITEM:
        case MediaItemTypes.URL_MEDIA_ITEM:
          popupType = PopupTypes.MEDIA_PREVIEW;
          break;
        case MediaItemTypes.JACKPOT_MEDIA_ITEM:
          popupType = PopupTypes.MEDIA_JACKPOT_VALUE;
          break;
        case MediaItemTypes.TICKER_TAPE_MEDIA_ITEM:
          popupType = PopupTypes.MEDIA_TICKER_TAPE;
          break;
        case MediaItemTypes.RICH_TEXT_MEDIA_ITEM:
          popupType = PopupTypes.MEDIA_RICH_TEXT;
          break;
        case MediaItemTypes.WEBPACKAGE_ITEM:
          popupType = PopupTypes.MEDIA_WEBPACKAGE;
          break;
      }

      dispatch(
        popupActionSet(popupType, {
          ...media_item,
          folder_id: media_item.folder_id || 0,
        }),
      );

      if (!hasMediaEditRights) {
        dispatch(
          setInfoBar({
            message: 'You only have the rights to view this media item',
            state: InfoBarState.WARNING,
          }),
        );
      }
    })
    .catch(() => { });
};

export const cancelUpdateMediaItems = (
  arrangementId: number,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const promise = dispatch(
    fetchData(
      reverse(Api.ARRANGEMENT_MEDIA_ITEMS_CANCEL, {
        id: arrangementId,
      }),
      {
        method: 'POST',
      },
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      console.log(result);
    });
};

export const fetchMediaAction = ({
  pool_id,
  folder_id,
}: FetchMediaAction): ThunkAction<
  void,
  RootState,
  undefined,
  AnyAction
> => async (dispatch, getState) => {
  const { sort } = getState().media;
  const isSuperAdmin = dispatch(hasSuperAdminRights())
  const { customerOverride } = getState().superAdmin;

  const params = new URLSearchParams();
  if (sort) {
    params.set('sort_attribute', sort.attribute);
    params.set('sort_order', sort.order);
  }
  if (isSuperAdmin && customerOverride) {
    params.set('customer_id', customerOverride)
  }

  dispatch(mediaRequested());

  let apiUrl = reverse(Api.MEDIA);
  if (pool_id) {
    apiUrl = reverse(Api.MEDIA_BY_POOL, { pool_id });
  } else if (folder_id) {
    apiUrl = reverse(Api.MEDIA_BY_FOLDER, { folder_id });
  }

  if (sort) {
    apiUrl += `?${params.toString()}`;
  }

  const promise = dispatch(fetchData(apiUrl));

  promise
    .then(json => json.json())
    .then(result => {
      const successObject = {
        ...result,
        filter: { name: '', filetype: '' },
      };

      if (!folder_id) {
        successObject.folder_info = null;
        successObject.breadcrumbs = [];
      }

      dispatch(mediaSucceeded(successObject));
    })
    .catch(() => {
      dispatch(mediaFailed());
      dispatch(
        setInfoBar({
          message: 'Could not fetch media',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};

export const filterMediaAction = (
  name: string,
  filetype: string,
  folder_id?: string
): ThunkAction<void, RootState, undefined, AnyAction> => async (
  dispatch,
  getState,
  ) => {
    const { sort } = getState().media;

    const params = new URLSearchParams();
    if (name) {
      params.set('search', name);
    }
    if (filetype) {
      params.set('media_item_type_id', filetype);
    }
    if (sort) {
      params.set('sort_attribute', sort.attribute);
      params.set('sort_order', sort.order);
    }

    const baseUrl = folder_id ? reverse(Api.MEDIA_BY_FOLDER, { folder_id }) : Api.MEDIA
    const url =
      !!name || !!filetype || !!sort
        ? `${baseUrl}?${params.toString()}`
        : baseUrl;

    const promise = dispatch(fetchData(url));
    promise
      .then(json => json.json())
      .then(result => {
        dispatch(mediaSucceeded({ ...result, filter: { name, filetype } }));
      })
      .catch((err: string) => {
        console.error(err);
        dispatch(
          setInfoBar({
            message: 'Could not fetch media',
            state: InfoBarState.ERROR,
            timeout: 5000,
          }),
        );
      });
  };

export const sortMediaItemsAction = (
  sort: MediaItemSort,
  folder_id: string,
): ThunkAction<void, RootState, undefined, AnyAction> => async (
  dispatch,
  getState,
  ) => {
    const { filter } = getState().media;
    dispatch(mediaSorting(sort));
    if (filter && (filter.name || filter.filetype)) {
      dispatch(filterMediaAction(filter.name, filter.filetype, folder_id));
    } else {
      dispatch(fetchMediaAction(folder_id ? { folder_id } : {}));
    }
  };

export const selectMediaItems = (
  selectable: boolean,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  dispatch(mediaSelectable(selectable));
};

export const editMediaFolderAction = (
  id: number,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const promise = dispatch(fetchData(reverse(Api.MEDIA_FOLDER_PATCH, { id })));
  promise
    .then(json => json.json())
    .then(({ folder_info }) => {
      dispatch(popupActionSet(PopupTypes.MEDIA_FOLDER, folder_info));
    })
    .catch(() => { });
};

export const deleteMediaFolderAction = (
  id: number,
  breadCrumbs: MediaBreadcrumbs[],
  history: History,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const promise = dispatch(
    fetchData(reverse(Api.MEDIA_FOLDER_DELETE, { id }), {
      method: 'DELETE',
    }),
  );
  promise
    .then(() => {
      const currentFolderId =
        breadCrumbs &&
        breadCrumbs.length &&
        breadCrumbs[breadCrumbs.length - 1].id;

      if (id === currentFolderId && breadCrumbs.length >= 2) {
        const parentFolderId =
          breadCrumbs &&
          breadCrumbs.length &&
          breadCrumbs[breadCrumbs.length - 2].id;

        const location = {
          pathname: reverse(Api.MEDIA_DELETE, { id: parentFolderId }),
        };
        history.push(location);
      }
      if (id === currentFolderId && breadCrumbs.length === 1) {
        const location = {
          pathname: reverse(Api.MEDIA),
        };
        history.push(location);
      }
      dispatch(mediaFolderDeleted(id));
      dispatch(popupActionClear());
      dispatch(
        setInfoBar({
          message: 'Folder has been removed',
          timeout: 5000,
          state: InfoBarState.CHECK,
        }),
      );
    })
    .catch((err: string) => {
      console.error(err);
    });
};

export const saveMediaFolderAction = (
  values: {
    title: string;
    media_folder_id: number;
  },
  id: number,
  initial: {
    title: string;
    media_folder_id: number;
  },
  history: History,
  media: Media,
  setSubmitting: Function,
  customerOverride: string | undefined,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const folderData = new FormData();

  folderData.append('media_folder[title]', values.title);
  if (customerOverride) {
    folderData.append('media_folder[customer_id]', customerOverride);
  }
  if (values.media_folder_id >= 0) {
    folderData.append(
      'media_folder[media_folder_id]',
      values.media_folder_id === 0 ? '' : values.media_folder_id.toString(),
    );
  }

  const promise = dispatch(
    fetchData(
      id ? reverse(Api.MEDIA_FOLDER_PATCH, { id }) : Api.MEDIA_FOLDER_POST,
      {
        method: id ? 'PATCH' : 'POST',
        body: folderData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(({ media_folder }) => {
      if (id) {
        if (values.media_folder_id === initial.media_folder_id) {
          dispatch(mediaFolderUpdated(media_folder));
        } else {
          dispatch(mediaFolderDeleted(id));
        }

        if (values.media_folder_id >= 0) {
          dispatch(
            setInfoBar({
              message: 'The folder has been repositioned',
              action: [
                {
                  handle: () => {
                    history.push({
                      pathname:
                        values.media_folder_id === 0
                          ? Paths.MEDIA
                          : reverse(Paths.MEDIA_FOLDER, {
                            folder_id: values.media_folder_id,
                          }),
                    });
                  },
                  text: 'Go to folder',
                },
              ],
              timeout: 5000,
              state: InfoBarState.CHECK,
            }),
          );
        } else {
          dispatch(
            setInfoBar({
              message: 'The folder has been updated',
              timeout: 5000,
              state: InfoBarState.CHECK,
            }),
          );
        }
      } else {
        dispatch(mediaFolderAdded(media_folder.folder_info));
        dispatch(
          setInfoBar({
            message: 'The folder has been created',
            timeout: 5000,
            state: InfoBarState.CHECK,
          }),
        );
      }

      if (
        values.media_folder_id >= 0 &&
        media.folder_info &&
        media.folder_info.id !== values.media_folder_id
      ) {
        dispatch(mediaFolderDeleted(id));
      }

      dispatch(popupActionClear());
      setSubmitting(false);
    })
    .catch(() => {
      setSubmitting(false);
    });
};

export const fetchMediaValueTypesAction = (
  type: MediaValueType,
): ThunkAction<void, RootState, undefined, AnyAction> => async dispatch => {
  const promise = dispatch(
    fetchData(reverse(`${Api.MEDIA_TYPE_VALUES}?type=${type}`)),
  );

  promise
    .then(json => json.json())
    .then(result => {
      let jackpot;
      let tickerTape;

      if (type === MediaValueType.JACKPOT) {
        const {
          topic,
          currency,
          separator_and_decimals,
          font,
          formatting,
        } = result.values;

        const topics = topic.map((topicItem: { [key: string]: string }) => {
          const key = Object.keys(topicItem)?.[0];
          return { label: topicItem[key], value: Number(key) };
        });
        const currencies = currency.map((currencyItem: string) => {
          return { label: currencyItem, value: currencyItem };
        });
        const separatorAndDecimals = separator_and_decimals.map(
          (separatorAndDecimalsItem: string) => {
            return {
              label: separatorAndDecimalsItem,
              value: separatorAndDecimalsItem,
            };
          },
        );
        const fonts = font.map((fontItem: string) => {
          return { label: fontItem, value: fontItem };
        });
        const formattingOptions = formatting.map((formattingItem: string) => {
          return { label: formattingItem, value: formattingItem };
        });

        jackpot = {
          topics,
          currencies,
          separatorAndDecimals,
          fonts,
          formattingOptions,
        };
      }

      if (type === MediaValueType.TICKER_TAPE) {
        const { font, direction } = result.values;

        const fonts = font.map((fontItem: string) => {
          return { label: fontItem, value: fontItem };
        });

        const scrollDirection = direction.map((directionItem: string) => {
          return {
            label: directionItem.replaceAll('-', ' '),
            value: directionItem,
          };
        });

        tickerTape = {
          fonts,
          scrollDirection,
        };
      }

      const typeValues = {
        jackpot,
        tickerTape,
      };

      dispatch(mediaTypeValuesSucceeded(typeValues));
    })
    .catch(() => {
      dispatch(mediaTypeValuesFailed());
      dispatch(
        setInfoBar({
          message: 'Could not fetch media type values',
          state: InfoBarState.ERROR,
          timeout: 5000,
        }),
      );
    });
};
