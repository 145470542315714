
import React, {useState, useEffect, useMemo, FC} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';
import moment from 'moment-timezone';
import { RRule, rrulestr } from 'rrule';
import { format, parseISO } from 'date-fns';
import {useHistory, useLocation} from "react-router-dom";
import {reverse} from "named-urls";

import Input from 'components/form/input/Input';
import DateInput from 'components/form/datetime/DateInput';
import TimeInput from 'components/form/datetime/TimeInput';
import Recurring from 'components/form/recurring/Recurring';
import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import ButtonGroup from 'components/button/ButtonGroup';
import Status from 'components/status/Status';
import Icon from 'components/icon/Icon';
import Text from 'components/typography/text/Text';
import {
  PopupStickyHeader,
  PopupStickyMain,
  PopupStickyFooter,
  PopupStickyForm,
} from 'components/popup/Popup';
import Priority from 'components/form/priority/Priority';
import PriorityOptions from 'components/form/priority/Priority.enum';
import PopupLabels from 'components/popup-labels/PopupLabels';
import getRepeatFromRrule from 'components/form/recurring/Recurring.helpers';
import ButtonSelectShow from 'components/button/buttonSelectShow/ButtonSelectShow';

import UserRight from 'constants/UserRight.enum';
import defaultTimezone from 'constants/DefaultTimezone';
import { Paths } from 'constants/Routes.enum';
import HistoryLocationState from "constants/HistoryState";

import useCalendarDates from 'hooks/useCalendarDates';

import {
  addEventAction,
  clearHistoryFromEventState,
  deleteEventAction,
  toggleActiveEventAction,
} from 'store/events/eventsActions';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import { setInfoBar, infoBarHide } from 'store/info/infoActions';
import { InfoBarState } from 'store/info/infoActions.enum';
import { hasUserRights } from 'store/auth/hasUserRights';
import { RootState } from 'store/rootState';

import { EventInitialValues, EventProps } from './Event.types';
import PopupTypes from 'constants/PopupTypes.enum';

const Event: FC<EventProps> = ({
  startDate,
  endDate,
  roundTime,
  id,
  title,
  rrule,
  arrangement,
  active,
  pending_approval,
  pending_changes,
  priority,
}) => {
  const dispatch = useDispatch();
  const pool = useSelector((state: RootState) => state.pool);
  const infoBar = useSelector((state: RootState) => state.info.bar);
  const hasEventEditRights = dispatch(hasUserRights(UserRight.EVENTS_EDIT));
  const timeZone = useSelector((state: RootState) => state.pool?.location?.timezone_info);
  const { state } = useLocation<HistoryLocationState>();
  const history = useHistory();

  const [start, end] = useCalendarDates({
    startDate,
    endDate,
    roundTime,
    timeZone,
  });

  const dateUntil = useMemo(() => {
    if (rrule) {
      return rrulestr(rrule).origOptions.until;
    }

    const nextYear = start && moment(start)
      .add(1, 'y')
      .utcOffset(0)
      .format();

    return nextYear;
  }, [rrule, start]);

  const repeat = useMemo(() => {
    return getRepeatFromRrule(rrule);
  }, [rrule]);

  const dailyInterval = useMemo(() => {
    if (rrule) {
      const parsedRRule = RRule.fromString(rrule);
      if (parsedRRule.options.freq === RRule.DAILY) {
        return parsedRRule.options.interval.toString();
      }
    }
    return '';
  }, [rrule]);

  const weeklyDays = useMemo(() => {
    if (rrule) {
      const parsedRRule = RRule.fromString(rrule);
      if (
        parsedRRule.options.freq === RRule.WEEKLY &&
        Array.isArray(parsedRRule.options.byweekday)
      ) {
        return parsedRRule.options.byweekday;
      }
    }
    return '';
  }, [rrule]);

  const monthlyInterval = useMemo(() => {
    if (rrule) {
      const parsedRRule = RRule.fromString(rrule);
      if (
        parsedRRule.options.freq === RRule.MONTHLY &&
        Array.isArray(parsedRRule.options.bysetpos)
      ) {
        return parsedRRule.options.bysetpos[0];
      }
    }
    return '';
  }, [rrule]);

  const monthlyIntervalDay = useMemo(() => {
    if (rrule) {
      const parsedRRule = RRule.fromString(rrule);
      if (
        parsedRRule.options.freq === RRule.MONTHLY &&
        Array.isArray(parsedRRule.options.byweekday)
      ) {
        return parsedRRule.options.byweekday.toString();
      }
    }
    return '';
  }, [rrule]);

  const monthlyDays = useMemo(() => {
    if (rrule) {
      const parsedRRule = RRule.fromString(rrule);
      if (
        parsedRRule.options.freq === RRule.MONTHLY &&
        Array.isArray(parsedRRule.options.bymonthday)
      ) {
        return parsedRRule.options.bymonthday;
      }
    }
    return '';
  }, [rrule]);

  const [isActive, setActive] = useState(active);
  const [isSubmitting, setSubmitting] = useState(false);

  const initialValues = useMemo(() => {
    return {
      name: title || '',
      startDate: start,
      startTime: start,
      dateUntil,
      endDate: end,
      endTime: end,
      rrule: rrule || '',
      repeat,
      weeklyDays,
      dailyInterval,
      monthlyInterval,
      monthlyIntervalDay,
      monthlyDays,
      arrangementId: (arrangement && arrangement.id) ? arrangement.id.toString() : '',
      priority: priority || PriorityOptions.PRIORITY_LOW,
    }
  }, [title, arrangement, rrule, priority, dailyInterval, dateUntil, end, monthlyDays, monthlyInterval, monthlyIntervalDay, repeat, start, weeklyDays]);

  // Initial values as state to update form
  const [initialForm, setInitialForm] = useState<EventInitialValues>(() => {
    // Fill with values from router state if available
    if (state?.fromEventFormFormValues) {
      return {
        ...state.fromEventFormFormValues,
        startDate: moment.tz(state.fromEventFormFormValues?.startDate, timeZone),
        startTime: moment.tz(state.fromEventFormFormValues?.startTime, timeZone),
        endDate: moment.tz(state.fromEventFormFormValues?.endDate, timeZone),
        endTime: moment.tz(state.fromEventFormFormValues?.endTime, timeZone),
        dateUntil: moment.tz(state.fromEventFormFormValues?.dateUntil, timeZone),
      }
    }

    return initialValues;
  });

  // Update state with new values from props
  useEffect(() => {
    // Only when router state is not available
    if (state?.fromEventFormFormValues === undefined) {
      setInitialForm(prev => equal(prev, initialValues) ? prev : initialValues);
    }
  }, [initialValues, state]);

  const dateTimeChanges = (currentDateString: string, changedDateString: string) => {
    const currentDate = format(new Date(currentDateString), 'MM/dd/yyyy');
    const changedDate = format(new Date(changedDateString), 'MM/dd/yyyy');
    const currentTime = format(new Date(currentDateString), 'p');
    const changedTime = format(new Date(changedDateString), 'p');

    const dateChanged =
      currentDate !== changedDate && currentTime === changedTime;
    const timeChanged =
      currentDate === changedDate && currentTime !== changedTime;
    const dateAndTimeChanged =
      currentDate !== changedDate && currentTime !== changedTime;

    if (dateChanged) {
      return 'dateChanged';
    }
    if (timeChanged) {
      return 'timeChanged';
    }
    if (dateAndTimeChanged) {
      return 'dateAndTimeChanged';
    }
    return 'nothingChanged';
  };

  const combineDates = (d: moment.Moment | null, t?: moment.Moment | null | object): string => {
    const date = moment.tz(d, timeZone || defaultTimezone);
    const time = moment.tz(t, timeZone || defaultTimezone);

    const dateTimeCombined = date
      .clone()
      .set({ hour: time.hour(), minute: time.minute() })
      .tz(timeZone || defaultTimezone)
      .format();

    return dateTimeCombined;
  };

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string().required('Name is a required field'),
      arrangementId: Yup.number().required('Show is a required field'),
      startTime: Yup.object().when(
        ['startDate', 'endDate', 'endTime'],
        (startDate: moment.Moment | null, endDate: moment.Moment | null, endTime: moment.Moment | null) => {
          const endToCompare = combineDates(endDate, endTime);
          return Yup.object()
            .test('invalid', 'Invalid start time', value => {
              return moment(value).isValid();
            })
            .test(
              'cannot-be-after',
              'Start date/time cannot be after or equal to end date/time',
              value => {
                const startToCompare = combineDates(startDate, value);
                return startToCompare < endToCompare;
              },
            )
            .test(
              'cannot-be-before-current-date',
              'Start date/time cannot be before current date/time',
              value => {
                const startToCompare = combineDates(startDate, value);
                const isNewEvent = id === undefined;

                if (isNewEvent) {
                  return (
                    moment
                      .tz(new Date(), timeZone || defaultTimezone)
                      .format() < startToCompare
                  );
                }
                return true;
              },
            );
        },
      ),
      endTime: Yup.object().when(
        ['startDate', 'endDate', 'startTime'],
        (startDate: moment.Moment | null, endDate: moment.Moment | null, startTime: moment.Moment | null) => {
          const startToCompare = combineDates(startDate, startTime);
          return Yup.object()
            .test('invalid', 'Invalid end time', value => {
              return moment(value).isValid();
            })
            .typeError('Invalid end time')
            .test(
              'cannot-be-before',
              'End date/time cannot be before or equal to start date/time',
              value => {
                const endToCompare = combineDates(endDate, value);
                return startToCompare < endToCompare;
              },
            )
            .test(
              'cannot-be-before-current-date',
              'End date/time cannot be before current date/time',
              value => {
                const endToCompare = combineDates(endDate, value);
                const isNewEvent = id === undefined;

                if (isNewEvent) {
                  return (
                    moment
                      .tz(new Date(), timeZone || defaultTimezone)
                      .format() < endToCompare
                  );
                }
                return true;
              },
            );
        },
      ),
      weeklyDays: Yup.string()
        .notRequired()
        .when('repeat', {
          is: val => val === 'weekly',
          then: Yup.string().required(
            'Which days of the week is a required field',
          ),
          otherwise: Yup.string().notRequired(),
        }),
      dailyInterval: Yup.string()
        .notRequired()
        .when('repeat', {
          is: val => val === 'daily',
          then: Yup.string().required('Every is a required field'),
          otherwise: Yup.string().notRequired(),
        }),
      monthlyInterval: Yup.string()
        .notRequired()
        .when('repeat', {
          is: val => val === 'monthly_day',
          then: Yup.string().required('Every is a required field'),
          otherwise: Yup.string().notRequired(),
        }),
      monthlyIntervalDay: Yup.string()
        .notRequired()
        .when('repeat', {
          is: val => val === 'monthly_day',
          then: Yup.string().required('Day of the month is a required field'),
          otherwise: Yup.string().notRequired(),
        }),
      monthlyDays: Yup.string()
        .notRequired()
        .when('repeat', {
          is: val => val === 'monthly_number',
          then: Yup.string().required(
            'Which days of the month is a required field',
          ),
          otherwise: Yup.string().notRequired(),
        }),
      dateUntil: Yup.string()
        .test(
          'Invalid date',
          'Enter a valid Until date',
          value => value !== 'Invalid date',
        )
        .when(['startDate'], (startDate: moment.Moment | null) => {
          return Yup.string().test(
            'cannot-be-before',
            'Until date cannot be before or equal to start date',
            value =>
              moment(startDate)
                .utc()
                .valueOf() <=
              moment(value)
                .utc()
                .valueOf(),
          );
        }),
    },
    [['endTime', 'startTime']],
  );

  const closePopup = () => {
    clearHistoryFromEventState(history, state);
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(infoBarHide());
    }
  };

  const onSubmit = (values: EventInitialValues) => {
    setSubmitting(true);

    const {
      name,
      startDate,
      startTime,
      endDate,
      endTime,
      rrule,
      arrangementId,
      priority,
    } = values;

    const updatedValues = {
      ...values,
      monthlyIntervalDay: values.monthlyIntervalDay.toString(),
    };

    if (equal(updatedValues, initialValues)) {
      clearHistoryFromEventState(history, state);
      dispatch(popupActionClear());
      return;
    }

    const startDateInTz = startDate && moment.tz(
      startDate.format(),
      timeZone || defaultTimezone,
    );
    const startTimeInTz = startTime && moment.tz(
      startTime.format(),
      timeZone || defaultTimezone,
    );
    const endDateInTz = endDate && moment.tz(
      endDate.format(),
      timeZone || defaultTimezone,
    );
    const endTimeInTz = endTime && moment.tz(
      endTime.format(),
      timeZone || defaultTimezone,
    );

    // Combine time and date fields
    startDateInTz && startTimeInTz && startDateInTz.set({
      hour: startTimeInTz.hour(),
      minute: startTimeInTz.minute(),
      second: 0,
    });
    endDateInTz && endTimeInTz && endDateInTz.set({
      hour: endTimeInTz.hour(),
      minute: endTimeInTz.minute(),
      second: 0,
    });

    const startDateUTC = startDateInTz ? startDateInTz.utc().format() : '';
    const endDateUTC = endDateInTz ? endDateInTz.utc().format(): '';

    const eventData = new FormData();
    eventData.append('scheduled_event[title]', name);
    eventData.append('scheduled_event[start_at]', startDateUTC);
    eventData.append('scheduled_event[end_at]', endDateUTC);
    eventData.append('scheduled_event[arrangement_id]', arrangementId.toString());
    eventData.append('scheduled_event[priority]', priority.toString());
    eventData.append('pool_id', pool.id.toString());
    eventData.append('arrangement_id', arrangementId.toString());

    if (rrule) {
      eventData.append('scheduled_event[rrule]', rrule);
    }

    dispatch(addEventAction(eventData, id, setSubmitting, state, history));
  };

  const selectShowClick = () => {
    const location = (id: number, newEvent: boolean) => {
      return {
        pathname: reverse(Paths.POOLS_ARRANGEMENTS, { id: pool.id }),
        state: {
          // @ts-ignore
          ...history?.location?.state,
          fromEvent: id,
          fromEventFormFormValues: {
            ...values,
            startDate: values.startDate?.utc().format(),
            startTime: values.startTime?.utc().format(),
            endDate: values.endDate?.utc().format(),
            endTime: values.endTime?.utc().format(),
            dateUntil: values.dateUntil ? new Date(values.dateUntil) : undefined,
          },
          newEvent:
            // @ts-ignore
            newEvent || history?.location?.state?.newEvent || false,
        },
      };
    };

    if (id === undefined || id === null) {
      history.push(location(id, true));
    } else {
      history.push(location(id, false));
    }
    dispatch(popupActionClear());
  };

  const clearSelectedShow = () => {
    setFieldValue('arrangementId', '');
  };

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    initialTouched,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset className="fieldset--flatten">
          <PopupLabels labels={[pool.name, pool.location.name]} />

          <FormBlock flatten>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="New event"
              value={
                (pending_approval && pending_changes?.title) || values.name
              }
              onChange={handleChange}
              fontSize="large"
              error={touched.name && errors.name}
              suffix={<Icon name="edit" />}
              isChanged={
                (pending_approval &&
                  pending_changes &&
                  !!pending_changes.title) ||
                (pending_approval && pending_changes === undefined)
              }
              disabled={pending_approval || !hasEventEditRights}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyHeader>

      <PopupStickyMain>
        {id && (
          <fieldset className="fieldset--small">
            <Status isActive={isActive} needsApproval={pending_approval} />
          </fieldset>
        )}

        <fieldset>
          <legend>Date and time</legend>
          <Text color="grey-black">
            All dates and times are displayed in local timezone of device
          </Text>
          <FormBlock noSpacing>
            <FormBlock ignoreSplit>
              <DateInput
                label="Starts"
                name="startDate"
                id="startDate"
                placeholder="Date"
                value={
                  (pending_approval &&
                    pending_changes?.start_at &&
                    parseISO(pending_changes.start_at)) ||
                  values.startDate
                }
                // @ts-ignore
                minDate={
                  !id
                    ? moment
                        .tz(new Date(), timeZone || defaultTimezone)
                        .format()
                    : moment(0).format()
                }
                onChange={setFieldValue}
                isChanged={
                  (pending_approval &&
                    pending_changes &&
                    !!pending_changes.start_at &&
                    (dateTimeChanges(
                      startDate,
                      pending_changes.start_at,
                    ) === 'dateChanged' ||
                      dateTimeChanges(
                        startDate,
                        pending_changes.start_at,
                      ) === 'dateAndTimeChanged')) ||
                  (pending_approval && pending_changes === undefined)
                }
                error={touched.startDate && errors.startTime}
                disabled={pending_approval || !hasEventEditRights}
              />
              <TimeInput
                name="startTime"
                id="startTime"
                placeholder="Time"
                value={
                  (pending_approval &&
                    pending_changes?.start_at &&
                    parseISO(pending_changes.start_at)) ||
                  values.startTime
                }
                onChange={setFieldValue}
                isChanged={
                  (pending_approval &&
                    pending_changes &&
                    !!pending_changes.start_at &&
                    (dateTimeChanges(
                      startDate,
                      pending_changes.start_at,
                    ) === 'timeChanged' ||
                      dateTimeChanges(
                        startDate,
                        pending_changes.start_at,
                      ) === 'dateAndTimeChanged')) ||
                  (pending_approval && pending_changes === undefined)
                }
                disabled={pending_approval || !hasEventEditRights}
                error={touched.startTime && errors.startTime}
              />
            </FormBlock>
            <FormBlock ignoreSplit>
              <DateInput
                label="Ends"
                name="endDate"
                id="endDate"
                placeholder="Date"
                value={
                  (pending_approval &&
                    pending_changes?.end_at &&
                    parseISO(pending_changes.end_at)) ||
                  values.endDate
                }
                // @ts-ignore
                minDate={
                  !id
                    ? moment
                        .tz(new Date(), timeZone || defaultTimezone)
                        .format()
                    : moment(0).format()
                }
                onChange={setFieldValue}
                error={touched.endDate && errors.endTime}
                isChanged={
                  (pending_approval &&
                    pending_changes &&
                    !!pending_changes.end_at &&
                    (dateTimeChanges(endDate, pending_changes.end_at) ===
                      'dateChanged' ||
                      dateTimeChanges(endDate, pending_changes.end_at) ===
                        'dateAndTimeChanged')) ||
                  (pending_approval && pending_changes === undefined)
                }
                disabled={pending_approval || !hasEventEditRights}
              />
              <TimeInput
                name="endTime"
                id="endTime"
                placeholder="Time"
                value={
                  (pending_approval &&
                    pending_changes?.end_at &&
                    parseISO(pending_changes.end_at)) ||
                  values.endTime
                }
                onChange={setFieldValue}
                isChanged={
                  (pending_approval &&
                    pending_changes &&
                    !!pending_changes.end_at &&
                    (dateTimeChanges(endDate, pending_changes.end_at) ===
                      'timeChanged' ||
                      dateTimeChanges(endDate, pending_changes.end_at) ===
                        'dateAndTimeChanged')) ||
                  (pending_approval && pending_changes === undefined)
                }
                disabled={pending_approval || !hasEventEditRights}
                error={touched.endTime && errors.endTime}
              />
            </FormBlock>
          </FormBlock>

          {touched.startDate && errors.startDate && (
            <FormBlock error={errors.startDate} />
          )}
          {touched.startTime && errors.startTime && (
            <FormBlock error={errors.startTime} />
          )}
          {touched.endTime && errors.endTime && (
            <FormBlock error={errors.endTime} />
          )}

          <Recurring
            values={values}
            value={values.rrule}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            onChange={setFieldValue}
            name="rrule"
            startDate={values.startDate}
            pending_approval={pending_approval}
            pending_changes={pending_changes}
          />
        </fieldset>

        <fieldset>
          <legend>Shows</legend>
          <ButtonSelectShow
            onClick={selectShowClick}
            selected={values.arrangementId !== ''}
            error={touched.arrangementId && errors.arrangementId}
            arrangement={(pending_approval && pending_changes?.arrangement) || state?.arrangement || arrangement}
            pendingApproval={pending_approval && (pending_changes?.arrangement || !pending_changes)}
            disabled={pending_approval}
            onClearSelection={clearSelectedShow}
          />
        </fieldset>

        <fieldset>
          <legend>Priority</legend>
          <Text>
            When two overlapping events have the same priority, the first
            event that starts first will be shown.
          </Text>

          <FormBlock>
            <Priority
              label="Priority"
              id="priority"
              name="priority"
              onChange={setFieldValue}
              value={values.priority}
              disabled={pending_approval || !hasEventEditRights}
              pendingChange={pending_changes?.priority}
              pendingApproval={pending_approval && (pending_changes?.priority || !pending_changes)}
            />
          </FormBlock>
        </fieldset>
      </PopupStickyMain>
      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          {(id && hasEventEditRights && (
            <Button
              tag="a"
              size="medium"
              text="Delete"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(
                  setInfoBar({
                    message:
                      'Are you sure you want to delete this event?',
                    action: [
                      {
                        text: 'Yes, Delete',
                        type: 'button',
                        color: 'blue',
                        handle: () => dispatch(deleteEventAction(id)),
                      },
                      {
                        text: 'Cancel',
                        type: 'link',
                        handle: () => dispatch(infoBarHide()),
                      },
                    ],
                    state: InfoBarState.ERROR,
                  }),
                );
              }}
              disabled={!hasEventEditRights || isSubmitting}
            />
          )) || (
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                closePopup();
              }}
              disabled={isSubmitting}
            />
          )}

          <ButtonGroup>
            {id && (
              <Button
                tag="button"
                size="medium"
                scheme="link"
                text="Duplicate"
                hasShadow={false}
                handler={() => {
                  const duplicateData = {
                    title: `${title} - copy`,
                    startDate,
                    endDate,
                    rrule,
                    arrangement,
                    priority
                  }
                  dispatch(popupActionClear())
                  setTimeout(() => {
                    dispatch(popupActionSet(PopupTypes.EVENT, duplicateData))
                  }, 100)
                }}
              />
            )}
            {id && (
              <Button
                tag="button"
                size="medium"
                scheme="link"
                text={isActive ? 'Deactivate' : 'Activate'}
                hasShadow={false}
                handler={() => {
                  // @ts-ignore
                  if (hasEventEditRights) {
                    dispatch(
                      toggleActiveEventAction(
                        id,
                        isActive,
                        setActive,
                        pending_approval,
                      ),
                    );
                  }
                }}
                disabled={
                  pending_approval || !hasEventEditRights || isSubmitting
                }
              />
            )}

            {/* @ts-ignore */}
            {!pending_approval && initialTouched && hasEventEditRights ? (
              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Save and close"
                disabled={isSubmitting}
              />
            ) : (
              <Button
                tag="button"
                size="medium"
                text="Close"
                handler={() => {
                  closePopup();
                }}
              />
            )}
          </ButtonGroup>
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  )
}

export default Event;
