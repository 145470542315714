import React from 'react';

import Text from 'components/typography/text/Text';

import { PopupLabelsProps } from './PopupLabels.types';
import './PopupLabels.scss';

const PopupLabels = ({ labels }: PopupLabelsProps) => {
  return (
    <Text color="grey-black" className="popup-label" noTop>
      {labels.map(label => {
        return (
          <span key={label.replace(' ', '')} className="popup-label__item">
            {label}
          </span>
        );
      })}
    </Text>
  );
};

export default PopupLabels;
