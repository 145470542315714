export enum PopupTypes {
  ARRANGEMENT = 'arrangement',
  EVENT = 'event',
  TRIGGER = 'trigger',
  MEDIA_UPLOAD = 'media-upload',
  MEDIA_URL = 'media-url',
  MEDIA_PREVIEW = 'media-preview',
  MEDIA_JACKPOT_VALUE = 'media-jackpot-value',
  MEDIA_RICH_TEXT = 'media-rich-text',
  MEDIA_TICKER_TAPE = 'media-ticker-tape',
  MEDIA_WEBPACKAGE = 'media-webpackage',
  INFO = 'info',
  GROUP = 'group',
  DEVICE = 'device',
  DEVICE_ADD = 'devices-add',
  DEVICE_EDIT = 'device-edit',
  DEVICE_DETAIL = 'device-detail',
  MEDIA_FOLDER = 'media-folder',
  POOL_DETAIL = 'pool-detail',
  ACCOUNT_SETTINGS_TWO_FACTOR = 'account-settings-two-factor',
  ACCOUNT_SETTINGS_PASSWORD = 'account-settings-password',
  ACCOUNT_SETTINGS_EMAIL = 'account-settings-email',
  ACCOUNT_SETTINGS_VERIFY_EMAIL = 'account-settings-verify-email',
  MANAGEMENT_USER = 'management-user',
  MANAGEMENT_USER_ROLE = 'management-user-role',
  MANAGEMENT_LOCATION = 'management-location',
  LOG_DETAILS = 'log-details',
  NOTIFICATION = 'notification',
  INPUTS_INPUT_DEVICES_ADD = 'inputs-input-devices-add',
  INPUTS_INPUT_DEVICE_EDIT = 'inputs-input-device-edit',
  INPUTS_INPUT_SIGNAL_EDIT = 'inputs-input-signal-edit',
}

export enum PopupStyles {
  DEFAULT = 'default',
  STICKY = 'sticky',
}

export default PopupTypes;
