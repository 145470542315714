import equal from 'fast-deep-equal';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button from 'components/button/Button';
import FormBlock from 'components/form/block/FormBlock';
import DragSwitch from 'components/form/dragSwitch/DragSwitch';
import Input from 'components/form/input/Input';
import { InputFontSize } from 'components/form/input/Input.enum';
import Icon from 'components/icon/Icon';
import LabelValueList from 'components/list/labelValueList/LabelValueList';
import {
  PopupStickyFooter,
  PopupStickyForm,
  PopupStickyHeader,
  PopupStickyMain,
} from 'components/popup/Popup';
import Heading from 'components/typography/heading/Heading';

import UserRight from 'constants/UserRight.enum';

import { hasUserRights } from 'store/auth/hasUserRights';
import { setInfoBarShow } from 'store/info/infoActions';
import {
  editInputSignalAction,
  toggleActiveInputSignalAction,
} from 'store/inputSignals/inputSignals/inputSignalsActions';
import { popupActionClear } from 'store/popup/popupActions';
import { Location } from 'store/rootState';

import InputSignalAttachedTriggersOverview from './InputSignalAttachedTriggersOverview';
import { InputSignalEditProps, InputSignalValues } from './InputSignalEdit.types';
import InputSignalLatestData from './InputSignalLatestData';
import './InputSignalEdit.scss';

const InputSignalEdit: FC<InputSignalEditProps> = ({
  id,
  name,
  latest_data,
  locations,
  input_device,
  triggers,
  type,
  status,
}) => {
  const dispatch = useDispatch()
  const hasInputEditRights = dispatch(hasUserRights(UserRight.INPUT_EDIT));
  const [isActive, setActive] = useState(status === 'active');
  const [isSubmitting, setSubmitting] = useState(false);

  const [initialValues, setInitialValues] = useState<InputSignalValues>({
    id,
    name,
    locations,
    input_device,
    triggers,
    type,
    status,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
  });

  useEffect(() => {
    setInitialValues({
      id,
      name,
      locations,
      input_device,
      triggers,
      type,
      status,
    });
  }, [id, name, locations, input_device, triggers, type, status]);

  useEffect(() => {
    setActive(status === 'active')
  }, [status])

  const onSubmit = (values: InputSignalValues) => {
    if (equal(values, initialValues)) {
      dispatch(popupActionClear());
      dispatch(setInfoBarShow({ show: false }));
      return;
    }

    setSubmitting(true);
    dispatch(editInputSignalAction(id, values, setSubmitting));
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const inputSignalDetails = [
    {
      label: 'Type',
      value: type,
    },
    {
      label: 'Device',
      value: input_device?.name,
    },
    {
      label: 'Locations',
      value: locations
        .map((location: Location) => location.name)
        .sort()
        .join(', '),
    },
  ];

  return (
    <PopupStickyForm onSubmit={handleSubmit}>
      <PopupStickyHeader>
        <fieldset>
          <div className="input-signal-edit__header-layout">
            <FormBlock flatten>
              <Input
                containerClassName="input-signal-edit__header-layout__input"
                type="text"
                name="name"
                id="name"
                placeholder="Input device name"
                onChange={handleChange}
                value={values.name}
                error={touched.name && errors.name}
                fontSize={InputFontSize.LARGE}
                suffix={!hasInputEditRights ? null : <Icon name="edit" />}
                disabled={!hasInputEditRights || isSubmitting}
              />
            </FormBlock>
            {id && (
              <DragSwitch
                className="input-signal-edit__header-layout__drag-switch"
                id="active"
                name="active"
                labelOn="Active"
                labelOff="Disabled"
                isDisabled={
                  !hasInputEditRights || isSubmitting
                }
                isChecked={isActive}
                onChange={() => {
                  dispatch(
                    toggleActiveInputSignalAction(
                      id,
                      isActive,
                      setActive,
                      setSubmitting,
                    ),
                  );
                }}
              />
            )}
          </div>
        </fieldset>
      </PopupStickyHeader>
      <PopupStickyMain>
        <Heading level={2}>Input signal details</Heading>
        <LabelValueList
          values={inputSignalDetails}
          listId={1}
          loading={!type}
          loadingItemLength={3}
        />

        <InputSignalAttachedTriggersOverview
          isLoading={!triggers && Array.isArray(triggers)}
          triggers={triggers || []}
        />
        {latest_data && (latest_data.data || latest_data.timestamp) &&  (
          <InputSignalLatestData latest_data={latest_data} />
        )}
      </PopupStickyMain>

      <PopupStickyFooter>
        <FormBlock hasInlineChildren flatten>
          <Button
            tag="button"
            size="medium"
            text="Cancel"
            scheme="link"
            hasShadow={false}
            handler={() => {
              dispatch(popupActionClear());
            }}
            disabled={isSubmitting}
          />

          {/* TODO: fix dispatch typing in middleware */}
          {/* @ts-ignore */}
          {hasInputEditRights && (
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
              disabled={isSubmitting}
            />
          )}
        </FormBlock>
      </PopupStickyFooter>
    </PopupStickyForm>
  );
};

export default InputSignalEdit;
